.video-specialism {
  position: relative;
  padding-bottom: rem(350);
  overflow: hidden;

  @media (max-width: $lg) {
	padding-bottom: rem(200);
  }

  &__bg {
	width: 100%;
	height: auto;
	position: absolute;
	top: 75%;
	z-index: -1;

	@media (max-width: $md) {
	  top: 92%;
	}
  }
  &__monster {
	height: auto;
	position: absolute;
	width: min(492px, calc(492 / 1440 * 100dvw));
	right: 0;
	bottom: rem(36);

	@media (max-width: $xl) {
	  width: rem(250);
	}

	@media (max-width: $md) {
	  bottom: 0;
	  width: rem(230);
	}
  }
  &__content {
	columns: 2 rem(517);
	column-gap: rem(82);
	margin-top: rem(48);

	@media (max-width: $xl) {
	  columns: 2 rem(300);
	  column-gap: rem(42);
	}
  }
  &__item {
	width: 100%;
	height: 100%;
	display: inline-block;

	&-content {
	  padding: rem(22) rem(32) rem(32) rem(32);
	  background-color: $color-3;
	  border-bottom-left-radius: rem(16);
	  border-bottom-right-radius: rem(16);

	  @media (max-width: $lg) {
		padding: rem(16) rem(20) rem(20) rem(20);
	  }
	}

	&:nth-of-type(1) {
	  margin-bottom: rem(86);
	  @media (max-width: $xl) {
		margin-bottom: rem(43);
	  }
	}
	&:nth-of-type(2) {
	  margin-bottom: rem(92);
	  @media (max-width: $xl) {
		margin-bottom: rem(46);
	  }
	}

	&:nth-of-type(4) {
	  margin-top: rem(66);
	  margin-bottom: rem(72);
	  @media (max-width: $xl) {
		margin-top: rem(33);
		margin-bottom: rem(36);
	  }
	}

	&:nth-of-type(5) {
	  margin-bottom: rem(56);
	}

	@media (max-width: $md) {
	  margin-bottom: rem(36)!important;
	  margin-top: 0!important;
	}

	&-tag {
	  color: #FFD60A;
	  text-transform: uppercase;
	  font-size: rem(18);
	  line-height: 1;
	  font-weight: 700;
	}

	&-heading {
	  text-transform: uppercase;
	  font-size: rem(48);
	  font-weight: 900;
	  line-height: 120%;
	  margin-bottom: rem(16);

	  @media (max-width: $lg) {
		font-size: rem(32);
		margin-bottom: rem(8);
	  }
	}

	&-description {
	  font-size: rem(18);
	  font-style: normal;
	  font-weight: 300;
	  line-height: 160%;

	  @media (max-width: $xl) {
		line-height: 130%;
	  }
	}
  }
}
