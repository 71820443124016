.s-textImageRight {
	margin-top: rem(125);

	.heading {
		margin-bottom: rem(15);

		font-size: rem(120);
		line-height: 1.2;

		@media (max-width: $lg) {
			font-size: rem(40);
			line-height: rem(44);

			&.entry {
				transform: none !important;
				white-space: normal;
			}
		}
	}

	&__2 {
		margin-top: rem(43);
		margin-bottom: 0;

		@media (min-width: $md) {
			margin-top: rem(103);
		}
	}

	&--health {
		margin-top: rem(55);

		@media (min-width: $xl) {
			&.l-container {
				max-width: rem(1280);
			}
		}

		@media (min-width: $md) {
			margin-top: rem(155);
		}
	}

	&--ar-vr {
		.heading {
			font-size: rem(72);
			line-height: rem(86);

			span {
				font-size: rem(110);
				line-height: rem(125);
			}

			@media (max-width: $xl) {
				span {
					font-size: rem(90);
					line-height: rem(90);
				}
			}

			@media (max-width: $lg) {
				span {
					font-size: rem(40);
					line-height: rem(44);
				}
			}
		}

		.s-textImageRight__content {
			margin-bottom: rem(80);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;

		gap: rem(40);

		@media (max-width: $md) {
			flex-direction: column;
		}
	}

	&__text {
		width: 50%;

		.heading {
			margin-bottom: rem(21);

			font-size: rem(72);
			line-height: rem(86);

			@media (max-width: $lg) {
				font-size: rem(40);
				line-height: rem(44);

				&.entry {
					transform: none !important;
					white-space: normal;
				}
			}
		}

		p {
			font-family: $font-base;
			font-size: rem(18);
			font-weight: 100;
			line-height: rem(28);
		}

		@media (max-width: $md) {
			width: 100%;
		}
	}

	&__image {
		display: flex;
		justify-content: end;

		width: 50%;

		figure {
			margin: 0;
		}

		figcaption {
			margin-top: rem(27);

			font-family: $font-base;
			font-size: rem(18);
			font-weight: 700;
			line-height: rem(24);
			text-align: center;
			color: #d1d1d6;
			width: 100%;
		}

		&.thin {
			width: 30%;
		}

		&--health {
			display: block;
		}

		@media (max-width: $md) {
			width: 100% !important;
			justify-content: center;
			align-items: center !important;
		}
	}
}
