@font-face {
  font-family: "Gradient Premium Black";
  src: url("../img/GradientPremium-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tungsten";
  src: url("../fonts/561f38b1f4570de0fb8a39d691ab058c.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/GothamRnd-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
