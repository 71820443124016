.s-textWithDoubleImage {
	margin-top: rem(100);
	margin-bottom: rem(100);

	@media (max-width: $md) {
		margin-top: rem(50);
		margin-bottom: rem(50);
	}

	@media (min-width: $xl) {
		&.l-container {
			max-width: rem(830);
		}
	}

	.st-boldArrow--provideBlock:hover {
		padding-left: 0;
	}

	&__heading {
		width: 100%;

		.heading {
			font-size: rem(64);
			line-height: rem(86);

			&.st-outline {
				opacity: 0.5;
			}

			@media (max-width: $md) {
				font-size: rem(40);
				line-height: rem(44);

				&.entry {
					transform: none !important;
				}
			}
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;

		gap: rem(40);

		@media (max-width: $md) {
			flex-direction: column;
		}
	}

	&__text {
		width: 87%;

		ul {
			li {
				span {
					padding-left: 0;
				}
			}
		}

		p {
			font-family: $font-base;
			font-size: rem(18);
			font-weight: 100;
			line-height: rem(28);
		}

		.s-textImageRight__image {
			width: 100%;

			margin-top: rem(27);
		}

		@media (max-width: $md) {
			width: 100%;
		}
	}

	&__image {
		display: flex;
		justify-content: end;

		width: 13%;

		figure {
			margin: 0;
		}

		figcaption {
			margin-top: rem(27);

			font-family: $font-base;
			font-size: rem(18);
			font-weight: 700;
			line-height: rem(24);
			text-align: center;
			color: #d1d1d6;
		}
	}
}
