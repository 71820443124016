.s-blobs {
  position: relative;
  width: 100%;
  height: 7600px;
  margin-top: -40%;

  @media (max-width: $lg) {
    height: 2700px;
    margin-top: 50%;
  }
  @media (max-width: $sm) {
    height: 2500px;
    margin-top: 350px;
  }
  #trigger {
    height: 50px;
    opacity: 0;
    background-color: blue;
    width: 50px;
    position: absolute;
  }

  &__wrapper {
    position: relative;
  }
  &__left {
    position: absolute;
    width: 30%;
    left: 15%;
    top: 30%;
    max-width: 377px;
    // display: none;
    @media (max-width: $lg) {
      max-width: none;
      width: 80%;
      left: 5%;
    }
  }
  &__right {
    position: absolute;
    right: 15%;
    top: 30%;
    width: 30%;
    z-index: 2;
    max-width: 400px;
    // display: none;
    @media (max-width: $lg) {
      max-width: none;
      top: 30%;
      width: 80%;
    }
  }
  .agency,
  .consultancy {
    opacity: 0;
    z-index: 5;
    margin-top: -20%;
    @media (max-width: $lg) {
      width: 100%;
      max-width: none;
      opacity: 1;
    }
  }
  .agency {
    transform: translateX(-100vh);
    @media (max-width: $lg) {
      transform: translateX(0vh) translateY(-70px);
      width: 100%;
    }
    &--plus {
      position: absolute;
      top: 20%;
      left: 47%;
      opacity: 0;
      z-index: 5;
      transform: translateX(-20vh);
      transform: scale(1.5) translateX(-20vh);
      @media (max-width: $lg) {
        display: block;

        left: 6%;
        transform: scale(0.7);
        top: 210px;
        opacity: 1;
      }
      @media (max-width: $sm) {
        top: 250px;
      }
    }
  }
  .consultancy {
    transform: translateX(100vh);
    z-index: 10;
    @media (max-width: $lg) {
      transform: translateX(0vh) translateY(200px);
      z-index: 10;
      width: 100%;
    }
  }
  &__heading {
    font-size: rem(72);
    font-family: $font-secondary;
    transform: translate(0);

    position: relative;
    z-index: 10;
    @media (max-width: $lg) {
      font-size: 48px;
    }
  }
  &__text {
    position: relative;
    z-index: 5;
  }
  &__gradient {
    &--light {
      position: absolute;
      top: 0;
      width: 1222px;
      z-index: -1;
      left: -150%;
      transform: scale(1.5);

      @media (max-width: $lg) {
        opacity: 1;
        top: -35%;
        transform: translateX(0);
        left: -100%;
      }

      @media (max-width: $sm) {
        left: -200%;
        transform: translateX(0);
      }
      &--right {
        left: -50%;
        @media (max-width: $lg) {
          left: -20%;
          transform: translateX(100%);
          top: -5%;
          display: none;
        }
      }
    }
    &--red {
      position: absolute;
      width: 1200px;
      left: -100%;
      top: -8.5%;
      transform: translateX(-100%);
      opacity: 0;
      @media (max-width: $lg) {
        transform: scale(1.7);
        opacity: 1;
        top: -20%;
        width: 900px;
        left: 0%;
      }
      @media (max-width: $sm) {
        top: -30%;
        width: 900px;
        height: 500px;
        left: -50%;
        transform: scale(1.3) translateX(0);
      }

      &--right {
        z-index: 3;
        transform: translateX(90%);

        @media (max-width: $lg) {
          left: -20%;
          display: none;
          top: -5%;
          z-index: 3;
          transform: scale(2) translateX(90%);
          width: 900px;
        }
        @media (max-width: $sm) {
          width: 600px;
          left: 100%;
        }
      }
    }
  }
  &__second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 10;
    height: 500px;
  }
  &__logo {
    margin-bottom: rem(0);
    position: relative;
    z-index: 15;
    opacity: 0;

    @media (max-width: $lg) {
      opacity: 0;
    }
  }
  &__p {
    opacity: 0;
    position: relative;
    z-index: 10;

    @media (max-width: $lg) {
      text-align: center;
      transform: translateY(-40%);
      padding: 16px;
    }
    @media (max-width: $sm) {
      margin-top: 0px;
      padding: 16px;
      text-align: center;
    }
  }
  &__impossible {
    z-index: 3;
    font-family: $font-secondary;
    font-size: 8vw;
    position: relative;
    text-align: center;
    transform: translateY(-80%);
    opacity: 0;
    @media (max-width: $lg) {
      font-size: 10vw;
      transform: translateY(-120%);
    }
    @media (max-width: $md) {
      transform: translateY(-150%);
      font-size: 10vw;
    }
    @media (max-width: $sm) {
      font-size: 15vw;
      transform: translateY(-150%);
    }
  }
  &__done {
    z-index: 3;
    position: relative;
    font-family: $font-secondary;
    font-size: 8vw;
    margin-top: rem(-50);
    text-align: center;
    margin-left: 12%;
    opacity: 0;
    transform: translate(100%, -100%);
    @media (max-width: $xl) {
      transform: translateY(-80%);
    }
    @media (max-width: $lg) {
      transform: translateY(-120%);
      font-size: 10vw;
    }
    @media (max-width: $md) {
      transform: translateY(-120%);
      font-size: 10vw;
    }
    @media (max-width: $sm) {
      font-size: 15vw;
      margin-left: -5%;
      transform: translateY(-130%);
    }
  }
}
