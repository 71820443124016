/**
 * Intro section.
 */

.s-intro {
	&__text {
		margin-top: $padding-200;
		margin-bottom: $padding-200;

		font-weight: 100;

		padding-inline: rem(105);
		&--less-margin {
			margin-bottom: rem(0);
		}
		@media (max-width: $lg) {
			margin-bottom: 0;

			font-size: rem(18);

			padding-inline: 0;
		}
		.c-text {
			margin-bottom: rem(16);
		}
		p {
			font-size: rem(32);
			@media (max-width: $lg) {
				font-size: rem(18);
				font-weight: 400;
				line-height: normal;
			}
		}
		span {
			display: inline-block;
		}
	}

	&--ar &__text {
		display: flex;
		flex-direction: column;
		align-items: center;

		margin-bottom: rem(90);

		padding-inline: rem(100);

		@media (max-width: $lg) {
			padding-inline: 0;
		}

		p {
			line-height: rem(51);

			@media (max-width: $lg) {
				line-height: normal;
			}
		}

		.c-btn {
			display: inline-block;

			padding: rem(4) rem(21) rem(8);

			margin-top: rem(20);
		}
	}

	&--ar {
		margin-bottom: rem(120);
	}

	&--ar .vimeo-container {
		height: 0;
		padding-bottom: 56.25%;

		position: relative;

		iframe {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&--ar {
		margin-top: rem(80);
		margin-bottom: rem(80);

		@media (min-width: $lg) {
			margin-top: rem(192);
			margin-bottom: rem(147);
		}

		.s-intro__text {
			margin-top: rem(80);

			@media (min-width: $lg) {
				margin-top: rem(100);
			}

			.c-btn {
				margin-top: rem(83);
			}
		}
	}

	&--vr {
		margin-top: rem(23);
		margin-bottom: rem(80);

		&.s-intro--ar-vr{
			margin-top: rem(47);
		}

		@media (min-width: $lg) {
			margin-top: rem(23);
			margin-bottom: rem(104);
		}

		.vimeo-container {
			margin-top: rem(85);

			@media (min-width: $md) {
				margin-top: rem(115);
			}
		}

		.s-intro__text {
			margin-top: 0;

			text-align: center;

			.c-text {
				margin-bottom: rem(51);

				line-height: rem(28);
				text-align: start;

				@media (min-width: $md) {
					margin-bottom: rem(81);
					line-height: rem(50.8);
				}
			}

			.c-btn {
				padding: rem(10) rem(30);

				background-color: $color-3;
				border-color: $color-3;

				border-radius: rem(40);

				font-size: rem(16);

				.arrow{
					width: rem(16);
					transform: translateY(-5px);

					&.long{
						width: rem(25);
					}
				}

				@media (min-width: $md) {
					padding: rem(15) rem(100);

					font-size: rem(32);
				}
			}
		}
	}

	&--ai {
		.st-outline--semitransparent {
			opacity: .5 !important;
		}
	}
}
