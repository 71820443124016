.s-imageWithCaption {
	max-width: rem(972);

	margin: rem(138) auto rem(60) auto;

	&-ar-vr {
		margin-bottom: rem(115);
	}

	figure {
		margin: 0;
	}

	figcaption {
		margin-top: rem(27);

		font-family: $font-base;
		font-size: rem(18);
		font-weight: 700;
		line-height: rem(24);
		text-align: center;
		color: #D1D1D6;

		@media (max-width: $md){
			margin-left: rem(20);
			margin-right: rem(20);
		}
	}
}
