.s-textLeftImageRight {
	margin-top: rem(55);
	margin-bottom: rem(50);

	@media (min-width: $md) {
		margin-top: rem(125);
		margin-bottom: rem(100);
	}

	&__2 {
		margin-top: rem(103);
		margin-bottom: 0;
	}

	&.s-textLeftImageRight--product-visualisation {
		.s-textImageRight__content {
			gap: rem(30);
		}

		.s-textImageRight__image {
			flex-direction: column;
			align-items: end;
			justify-content: start;

			width: 60%;

			&.product-visualisation {
				width: 50%;
			}

			figure {
				&:first-of-type {
					margin-bottom: rem(70);
				}
			}

			@media (max-width: $lg) {
				width: 40%;
			}
		}

		.s-textLeftImageRight__text {
			width: 42%;

			&.wider {
				width: 49%;
			}

			&.product-visualisation {
				width: 71%;
			}

			@media (max-width: $lg) {
				width: 55%;
			}

			p {
				font-family: $font-base;
				font-size: rem(32);
				line-height: rem(51);

				@media (max-width: $md) {
					font-size: rem(18);
					line-height: rem(28);
				}
			}
		}

		.s-textImageRight__video {
			width: 41%;

			@media (max-width: $md) {
				width: 100%;
			}
			figcaption {
				text-align: center;
			}
		}
	}

	&--ar-vr {
		.heading {
			font-size: rem(72);
			line-height: rem(86);

			span {
				font-size: rem(110);
				line-height: rem(125);
			}
		}

		.s-textImageRight__content {
			margin-bottom: rem(80);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;

		gap: rem(40);

		@media (max-width: $md) {
			flex-direction: column;
		}
	}

	&__text {
		width: 50%;

		.heading {
			margin-bottom: rem(15);

			font-size: rem(72);
			line-height: rem(86);

			&.st-outline {
				opacity: 0.5;
			}

			@media (max-width: $lg) {
				font-size: rem(40);
				line-height: rem(44);
				white-space: normal !important;

				&.entry {
					transform: none !important;
				}
			}
		}

		p {
			font-family: $font-base;
			font-size: rem(18);
			font-weight: 100;
			line-height: rem(28);
		}

		&-left {
			float: left;
			width: 40%;
			margin-right: 40px;
			font-size: rem(24) !important;
			line-height: rem(38) !important;

			@media (max-width: $lg) {
				font-size: rem(18) !important;
				line-height: rem(28) !important;
				width: 45%;
			}

			@media (max-width: $md) {
				width: 100%;
			}
		}

		&-right {
			float: right;
			width: 52%;

			@media (max-width: $lg) {
				width: 45%;
			}

			@media (max-width: $md) {
				width: 100%;
			}
		}

		&.wider {
			width: 58%;
		}

		@media (max-width: $md) {
			width: 100% !important;
		}
	}

	&__image {
		display: flex;
		justify-content: end;

		width: 50%;

		figure {
			margin: 0;
		}

		figcaption {
			margin-top: rem(27);

			font-family: $font-base;
			font-size: rem(18);
			font-weight: 700;
			line-height: rem(24);
			text-align: center;
		}

		@media (max-width: $md) {
			width: 100%;
		}
	}
}
