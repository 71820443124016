/**
 * Convert px to rem.
 */

@function rem($sizeValue: 16) {
    @return calc($sizeValue / 16)+rem;
}

@mixin scrollbar($color-scrollbar, $color-scrollbar-bg) {
	scrollbar-width: thin;
	scrollbar-color: $color-scrollbar $color-scrollbar-bg;

	&::-webkit-scrollbar {
		width: rem(8);
	}
	&::-webkit-scrollbar-track {
		background: $color-scrollbar-bg;
	}
	&::-webkit-scrollbar-thumb {
		border: 0;
		border-radius: 0;
		background-color: $color-scrollbar;
	}
}