.bg-img-web-analytics,
.bg-img-seo {
  margin-top: calc((308 / 1440 * 100dvw) * -1);
  margin-bottom: calc((68 / 1440 * 100dvw) * -1);
  position: relative;
  padding-bottom: 66.3%;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  img {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	object-fit: cover;
	object-position: center;
  }
}

/* similar bg img for WEB ANALITICS*/
.bg-img-web-analytics {
  padding-bottom: 46.18%;
  margin-top: 0;
  margin-bottom: 0;
}
