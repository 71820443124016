.hero-vr {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	height: 100vh;
	padding-bottom: rem(90);

	text-align: right;
	overflow: hidden;

	#bg-video {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	@media (max-width: $md) {
		min-height: rem(568);
		height: 100vh;

		#bg-video {
			width: 100%;
			height: 100%;
		}
	}

	&__picture {
		img {
			@media (min-width: $xxl) {
				width: auto;
				max-height: 75vh;
			}
		}
	}
  	&.hero-vr-training {
	  background-image: url("../img/vr-training-hero.png");
	  @supports (background-image: url("../img/vr-training-hero.webp")) {
		background-image: url("../img/vr-training-hero.webp");
	  }
	  background-size: cover;
	  background-repeat: no-repeat;
	  background-position: center;
	}

	&.hero-vr-ar {
		background-image: url("../img/ar-vr-for-education-hero.png");
		background-position: bottom;
	  	background-repeat: no-repeat;
	  	background-size: cover;

		&--health {
		  background-image: url("../img/augmented-reality.jpg");
		  height: 100vh;
		  background-size: cover;
		  background-position-x: center;
		  @supports (background-image: url("../img/augmented-reality.webp")) {
			background-image: url("../img/augmented-reality.webp");
		  }
		}

		&--events {
			background-image: none;
		}
		&--only {
			&.hero-vr-ar {
				display: block;

				height: initial;
				padding-bottom: rem(0);

				background-image: none;
				@media (min-width: $xxl) {
					margin-bottom: rem(-50);
				}
				.st-scaleHeading--medium {
					margin-left: 0;

					font-size: rem(90);
					line-height: 1.3;
					@media (min-width: $md) {
						font-size: rem(90);
						line-height: 1;
					}
					@media (min-width: $xxl) {
						margin-left: 0;

						font-size: rem(177);
						line-height: rem(248);
					}
				}
			}

			.hero-vr__title {
				padding-bottom: 0;

				transform: translateY(0);

				opacity: 1;
				&-end {
					position: relative;

					opacity: 1;
				}
				@media (max-width: $lg) {
					width: min-content;

					line-height: 1.2;
				}
			}
			.hero-vr__superscript {
				position: absolute;
				top: rem(4);
				right: rem(-3);

				font-family: $font-secondary;
				@media (min-width: $md) {
					top: rem(3);
					right: rem(28);

					font-size: rem(30);
					line-height: 1;
				}
				@media (min-width: $xl) {
					top: rem(19);
					right: rem(60);
				}
				@media (min-width: $xxl) {
					top: rem(-70);
					right: rem(56);

					font-size: rem(60);
				}
			}
		}

		&--product-visualisation {
			background-image: url("../img/ar-vr-for-product-visualisation.png");

			.st-scaleHeading--medium {
				margin-left: 0 !important;

				font-size: rem(180);
				line-height: rem(180);

				span.small {
					font-size: rem(72);
					line-height: rem(86);
				}
			}
		}

		.hero-vr__title {
			line-height: 0;
		}

		.st-scaleHeading--medium {
			font-size: rem(180);
			line-height: rem(200);
			line-height: 0;

			span {
				font-size: rem(120);
				line-height: rem(120);

				@media (max-width: $xl) {
					font-size: rem(90) !important;
					line-height: 0;
				}
				@media (max-width: $md) {
				}
				@media (max-width: $md) {
					font-size: rem(48) !important;
				}
			}

			@media (min-width: $lg) {
				margin-left: (-365);
			}
		}

		.st-scaleHeading--normal {
			margin-right: rem(10);

			font-size: rem(120);
			line-height: rem(120);
		}
	}

	@media (min-width: $xxl) {
		background-position: center;
		background-size: cover;
	}

	&__title {
		transform: translateY(150px);
		margin-bottom: rem(-13);

		line-height: rem(16);
		text-align: center;

		opacity: 0;

		transition: opacity 1s, transform 1s;

		&.show-element {
			transform: translateY(0);

			opacity: 1;
		}
		@media (min-width: $lg) {
			.st-scaleHeading--medium {
				margin-left: rem(-115);
			}

			.st-scaleHeading--normal {
				margin-right: rem(-252);
			}
		}

		&-end {
			position: relative;
			transform: translateY(100px);

			opacity: 0;

			transition: opacity 2s, transform 2s;
			-webkit-transition: opacity 2s, transform 2s;

			&.show-element {
				transform: translateY(0);

				opacity: 1;
			}
		}

		@media (max-width: $lg) {
			width: auto;
			padding-bottom: rem(50);

			line-height: 1;
		}

		@media (max-width: $md) {
			line-height: 0.5;
			line-height: 0.5;
		}
	}

	&__superscript {
		display: inline-block;

		position: relative;
		margin-right: rem(-64);

		font-family: $font-secondary;
		font-size: rem(72);
		&--between {
			picture {
				img {
					transform: translateY(rem(14));
				}
			}
		}
		@media (min-width: $md) {
			picture {
				img {
					transform: translateY(rem(-13));
				}
			}
		}

		@media (max-width: $xl) {
			margin-right: rem(-30);

			font-size: rem(60);
		}

		@media (max-width: $lg) {
			font-size: rem(34);
		}

		@media (max-width: $md) {
			margin-right: rem(0);

			font-size: rem(18);
		}
	}
	&__gradient-bg {
		width: 100%;

		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		img {
			min-width: 100%;
		}
	}
}
