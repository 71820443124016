.c-text {
  font-size: rem(32);
  color: $color-2;
  line-height: rem(54);
  &:not(:last-child) {
    margin-bottom: rem(10);
  }
  @media (max-width: $lg) {
    font-size: rem(24);
  }

  &--large {
	font-size: rem(56);
	@media (max-width: $xl) {
	  font-size: rem(42);
	  line-height: 1.2;
	}
	@media (max-width: $lg) {
	  font-size: rem(32);
	  line-height: 1.1;
	}

	.s-pictureInformation--paid-media & ,
	.s-pictureInformation--web-analytics & ,
	.s-pictureInformation--seo & {
	  margin-bottom: rem(34);
	  @media (max-width: $lg) {
		margin-bottom: rem(20);
	  }
	}

	.s-pictureInformation--paid-media & {
	  font-size: rem(48);

	  @media (max-width: $xl) {
		font-size: rem(40);
		line-height: 1.2;
	  }
	  @media (max-width: $lg) {
		font-size: rem(30);
		line-height: 1.1;
	  }
	}
  }

  .s-pictureInformation--paid-media &,
  .s-pictureInformation--web-analytics &,
  .s-pictureInformation--seo & {
	line-height: 3rem;
	&:not(:last-child) {
	  margin-bottom: rem(16);
	}
  }
}
