.section-space {
    &--400 {
        margin-top: rem(400);
        @media (max-width: $lg) {
            margin-top: rem(200);
        }
    }
    &--240 {
        margin-top: rem(240);
        @media (max-width: $lg) {
            margin-top: rem(120);
        }
    }
    &--200 {
        margin-top: rem(200);
        @media (max-width: $lg) {
            margin-top: rem(100);
        }
    }
    &--160 {
        margin-top: rem(160);
        @media (max-width: $lg) {
            margin-top: rem(80);
        }
    }
    &--120 {
        margin-top: rem(120);
        @media (max-width: $lg) {
            margin-top: rem(60);
        }
    }
    &--90 {
        margin-top: rem(90);
        @media (max-width: $lg) {
            margin-top: rem(60);
        }
    }
    &--0 {
        margin-top: rem(0) !important;
    }
}

.section-space-bottom {
    &--240 {
        margin-bottom: rem(240);
        @media (max-width: $lg) {
            margin-bottom: rem(120);
        }
    }
    &--200 {
        margin-bottom: rem(200);
        @media (max-width: $lg) {
            margin-bottom: rem(80);
        }
    }
    &--160 {
        margin-bottom: rem(160);
        @media (max-width: $lg) {
            margin-bottom: rem(80);
        }
    }
}

.section-space-all-devices {
    &--200 {
        margin-top: rem(200);
    }
}
