.c-header {
  transition: 0.2s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 60;
  padding-block: rem(15);
  padding-inline: rem(20);
  transform: translateY(-200px);
  @media (max-width: $lg) {
    top: 0;
    padding: 0;
    transition: 0.2s;
  }
  &--open {
    transition: 1s;
    transform: translate(0);
  }

  &[style="transform: translateY(-100%);"] {
    .nav {
      height: calc(100dvh + 80px);
    }
  }

  &__container {
    display: flex;
    padding: 0 rem(20);
    justify-content: space-between;
    align-items: center;
    column-gap: rem(40);
    @media (max-width: $lg) {
      position: relative;
      z-index: 20;

      padding-block: rem(15);
      border-radius: 35px 0 0 0;
    }
    &--active {
      @media (max-width: $lg) {
        background-color: $color-3;
      }
    }
  }
  &__contact {
    font-style: normal;
    text-align: right;
    opacity: 1;
    margin-right: rem(30);
    max-width: 980px;
    z-index: 25;
    transition: 0.15s;

    a {
      margin-right: rem(10);
      margin-left: rem(10);
      font-size: rem(16);
      font-weight: 300;
      &:last-child {
        white-space: nowrap;
        margin-left: rem(10);
      }
    }

    span{
      @media (max-width: $lg) {
        display: none;
      }
    }

	&--address{
	  @media (max-width: $lg) {
		display: none;
	  }
	}

    &--mail{
      @media (max-width: $lg) {
        display: none;
      }
    }

    &--phone{
      @media (max-width: $lg) {
        display: none;
      }
    }

    &--cta {
      background: transparent;
      border: 1px solid #fff;
      padding: rem(8) rem(20) rem(10) rem(20);
      position: relative;
      border-radius: rem(25);
      z-index: 25;
      font-size: rem(14) !important;
      font-weight: 700 !important;
      transition: $transition-speed ease-in-out !important;
      span {
        position: relative;
        z-index: 27;
        text-transform: uppercase;
        color: #1f232b;
        font-size: rem(16);
        font-weight: 700;
        transition: 0.15s;
      }
      &:hover {
        color: $color-1;
        background-color: #fff;
      }

      &.border-black {
        &:hover {
          background: $color-1;
          color: $text-color-base;
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
