//From down
$transitionFadeSpeed: 1s;
.js .fade-up {
	transform: translateY(150px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

.js .fade-up2 {
	transform: translateY(240px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

//From Up
.js .fade-down {
	transform: translateY(-100px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

//From Up Right
.js .fadeUp-right {
	transform: translate(-50px, 100px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

//From Up Left
.js .fadeUp-left {
	transform: translate(50px, 100px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

//From  Left
.js .fade-left {
	transform: translateX(100px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

.js .fade-left2 {
	transform: translateX(200px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

//From  right
.js .fade-right {
	transform: translateX(-100px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

.js .fade-right2 {
	transform: translateX(-200px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
}

.js .heading {
	transform: translateX(-100px);

	opacity: 0;

	transition: opacity $transitionFadeSpeed, transform $transitionFadeSpeed;
	@media (max-width: 600px) {
		transform: translateX(10%);
	}
}

.js .js-step {
	transform: translateY(100px);

	transition: transform $transitionFadeSpeed;
	&--1,
	&--2,
	&--3 {
		transform: translate(0);
	}
	&--2 {
		transition-delay: .5s;
	}
	&--3 {
		transition-delay: 1s;
	}
}
.js .moreTransform-1 {
	transform: translateX(150px);
}
.js .moreTransform-2 {
	transform: translateX(200px);
}

.js .st-boldUnderline__changeColor,
.js .st-boldUnderline__changeColor2 {
	color: transparent;

	background-color: transparent;
	opacity: 1;

	transition: 2s .5s;
	&:before {
		content: '';

		width: 0;
		height: 2px;

		position: absolute;
		bottom: rem(-3);
		left: 0;

		background: $gradient-1;

		animation: changeSize 2s 1s;

		animation-fill-mode: forwards;
	}
	&:after {
		content: 'Impossible. Done.';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		font-weight: bold;

		background: linear-gradient(to right, #F39D9E 0%, #D11452 50%, #7C1436 100%);
		background-clip: text;
		opacity: 0;

		animation: changeColor 2s 1s;

		animation-fill-mode: forwards;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.js .st-boldUnderline__changeColor2 {
	&:after {
		content: 'Get in touch';
	}
	&--alt {
		&:after {
			content: 'reality bending work.';
		}
	}
}

.js .st-boldUnderline__changeColor3 {
	color: transparent;

	background-color: transparent;
	opacity: 1;

	transition: 2s .5s;

	&:after {
		content: 'next generation of training.';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		font-weight: bold;

		background: linear-gradient(to right, #F39D9E 0%, #D11452 50%, #7C1436 100%);
		background-clip: text;
		opacity: 0;

		animation: changeColor 2s 1s;

		animation-fill-mode: forwards;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.js .st-boldUnderline__changeColor4 {
	color: transparent;

	background-color: transparent;
	opacity: 1;

	transition: 2s .5s;

	&:after {
		content: 'bring a new dimension to learning.';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		font-weight: bold;

		background: linear-gradient(to right, #F39D9E 0%, #D11452 50%, #7C1436 100%);
		background-clip: text;
		opacity: 0;

		animation: changeColor 2s 1s;

		animation-fill-mode: forwards;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.js .st-boldUnderline__changeColor5 {
	color: transparent;

	background-color: transparent;
	opacity: 1;

	transition: 2s .5s;

	&:after {
		content: 'supercharge your healthcare comms.';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		font-weight: bold;

		background: linear-gradient(to right, #F39D9E 0%, #D11452 50%, #7C1436 100%);
		background-clip: text;
		opacity: 0;

		animation: changeColor 2s 1s;

		animation-fill-mode: forwards;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.js .st-boldUnderline__changeColor6 {
	color: transparent;

	background-color: transparent;
	opacity: 1;

	transition: 2s .5s;

	&:after {
		content: 'add a magical AR digital layer to your products.';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		font-weight: bold;

		background: linear-gradient(to right, #F39D9E 0%, #D11452 50%, #7C1436 100%);
		background-clip: text;
		opacity: 0;

		animation: changeColor 2s 1s;

		animation-fill-mode: forwards;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.js .st-boldUnderline__changeColor7 {
	color: transparent;

	background-color: transparent;
	opacity: 1;

	transition: 2s .5s;

	&:after {
		content: 'truly dimension smashing.';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		font-weight: bold;

		background: linear-gradient(to right, #F39D9E 0%, #D11452 50%, #7C1436 100%);
		background-clip: text;
		opacity: 0;

		animation: changeColor 2s 1s;

		animation-fill-mode: forwards;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.js .is-visible {
	transform: none;

	opacity: 1;
}

.js .entry {
	transform: translateX(0px);

	opacity: 1;
	@media (max-width: 600px) {
		transform: translateX(-50%);
	}
}

.posAbsolute {
	position: absolute !important;
}

.closeParallax {
	height: 0;
	padding: 0;

	margin: 0;

	transition: height 2s;
}
