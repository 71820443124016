.s-pictureInformation {
	display: flex;
	flex-direction: column;

	width: 100%;

	margin-bottom: rem(186);

	font-size: rem(32);
	font-weight: 100;

	@media (max-width: $lg) {
		margin-bottom: rem(144);
	}

	&__header {
		padding: 0 rem(140);

		margin-bottom: rem(160);

		@media (max-width: $lg) {
			padding: 0 rem(20);

			margin-bottom: 100px;
		}

		h2 {
			margin-bottom: rem(10);
		}

		p {
			@media (max-width: $lg) {
				font-size: 18px;
			}
		}
	}

	&__img {
		display: flex;

		position: relative;
		margin-left: 15%;

		@media (max-width: $lg) {
			margin-left: 0;
		}

		&--2 {
			position: absolute;
			z-index: -1;
		}

		&__frame {
			position: absolute;
			top: 0;
			left: 0;

			animation: dpd1 10s infinite;
		}

		&__frame2 {
			position: absolute;
			top: 0;
			left: 0;

			animation: screenScroll2 10s infinite;
		}

		&__bar {
			position: absolute;
			z-index: 5;
			bottom: 0;
		}

		&__wrapper {
			position: relative;

			@media (max-width: $lg) {
			}
		}

		&__mobile {
			position: absolute;
		}

		&__mobile1 {
			position: absolute;
			top: -30%;
			left: 33%;

			border-radius: 19px;
			overflow: hidden;

			@media (max-width: $lg) {
				width: 33%;

				top: -88%;
				left: 16px;
			}

			@media (max-width: 775px) {
				width: 40%;
			}

			@media (max-width: $md) {
				top: -110%;
			}
		}

		&__mobile2 {
			max-width: 263px;

			position: absolute;
			top: -12%;
			left: 62%;

			border-radius: 19px;
			overflow: hidden;

			@media (max-width: $lg) {
				width: 33%;

				top: -60%;
				left: 55%;
			}

			@media (max-width: 775px) {
				width: 40%;
			}

			@media (max-width: $md) {
				top: -82%;
			}
		}
	}

	&__text {
		margin-top: rem(126);

		font-weight: 300;

		padding-inline: rem(120);

		&--2 {
			margin-top: 0;

			font-weight: 300;

			padding-inline: rem(120);

			@media (max-width: $lg) {
				padding: rem(16);

				margin-top: rem(96);

				font-size: rem(18);
			}
		}

		@media (max-width: $lg) {
			padding: rem(16);

			margin-top: rem(96);

			font-size: rem(18);
		}

		&--option2 {
			display: flex;

			margin-top: rem(260);

			column-gap: rem(30);

			@media (max-width: $lg) {
				flex-direction: column;

				margin-top: rem(180);

				row-gap: rem(30);
			}

			@media (max-width: $md) {
				margin-top: rem(0);
			}

			.s-pictureInformation {
				&__p {
					width: 50%;

					@media (max-width: $lg) {
						width: 100%;
					}
				}

				&__cols {
					flex-direction: column;

					width: 50%;
					padding: 0;

					margin: 0;

					row-gap: rem(40);

					@media (max-width: $lg) {
						width: 100%;
					}
				}

				&__col {
					width: 100%;

					margin-block: 0;
				}
			}
		}

		&--option3 {
			h2 {
				opacity: .5 !important;
			}

			.s-pictureInformation__cols {
				margin-top: rem(43);
			}

			.s-pictureInformation__col {
				@media (min-width: $lg) {
					flex: 0 1 37%;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&--last {
					@media (min-width: $lg) {
						flex: 1;

						margin-top: rem(-4);

						font-size: rem(32);
					}
				}
			}
		}
	}

	&__cols {
		display: flex;

		margin-top: rem(64);

		font-size: rem(18);

		column-gap: rem(40);

		@media (max-width: $lg) {
			flex-direction: column;

			margin-top: rem(32);
		}
	}

	&__col {
		width: 48%;

		&--last {
			width: 40%;
		}

		@media (max-width: $lg) {
			width: 100%;

			margin-bottom: rem(32);
		}
	}



	&--vr {
		margin-bottom: rem(51);

		&.s-pictureInformation--health {
			padding: 0;

			margin-bottom: 0;

			.s-pictureInformation__footer {
				margin-top: rem(80);

				font-size: rem(72);
				line-height: rem(82);

				@media (max-width: $lg) {
					margin-top: rem(60);
					font-size: rem(45);
				}

				@media (max-width: $md) {
					font-size: rem(34);
					line-height: 1.6;
				}
			}
		}

		&.s-pictureInformation--ar-vr {
			padding-left: rem(27);

			.s-pictureInformation__content {
				margin-top: rem(11);
			}

			.s-pictureInformation__col__left {
				flex: 0 0 35%;

				@media (min-width: $md){
					p {
						font-size: unset;
						line-height: unset;
					}
				}
			}

			.s-pictureInformation__col__right {
				flex: 0 0 52.5%;

				ul {
					margin-top: rem(50);
					margin-bottom: rem(50);

					li {
						span {
							padding-left: 0;
						}
					}
				}

				p {
					font-size: rem(18);
					line-height: rem(28);
				}
			}
		}

		&.s-pictureInformation--events {
			padding: 0;

			margin-top: 0;

			.s-pictureInformation__footer {
				margin-top: 0;

				font-size: rem(72);
				line-height: rem(82);

				@media (max-width: $lg) {
					font-size: rem(45);
				}

				@media (max-width: $md) {
					font-size: rem(34);
					line-height: 1.6;
				}
			}
		}

		h2 {
			margin-bottom: rem(28);

			font-size: rem(40);
			font-weight: 900;
			line-height: rem(40);

			@media (min-width: $md){
				font-size: rem(80);
				line-height: rem(80);
			}

			@media (min-width: $lg){
				font-size: rem(120);
				line-height: rem(120);
			}
		}

		.s-pictureInformation__content {
			display: flex;
			justify-content: space-between;

			width: 100%;

			gap: rem(32);

			@media (max-width: $md){
				flex-direction: column;
			}
		}

		.s-pictureInformation__col {
			&__left {
				flex: 0 0 57%;

				p {
					font-size: rem(18);
					line-height: 1.6;
				}
			}

			&__right {
				flex: 0 0 43%;

				ul {
					display: flex;
					flex-direction: column;
					justify-content: start;

					gap: rem(8);
					li {
						font-size: rem(18);
						line-height: 1.2;
					}
				}
			}
		}

		@media (min-width: $lg) {
			padding-right: rem(130);
			padding-left: rem(130);
		}
	}

	&--vr &__footer {
		margin-top: rem(120);

		font-family: $font-secondary;
		font-size: rem(60);
		text-align: center;

		@media (max-width: $lg) {
			margin-top: rem(60);

			font-size: rem(45);
		}

		@media (max-width: $md) {
			font-size: rem(34);
		}

		strong {
			color: $color-5;
		}
	}

	&--seo  {
	  .s-pictureInformation__cols {
		justify-content: space-between;
	  }

	  .s-pictureInformation__col {
		overflow: hidden;

		&--right {
		  position: relative;
		  overflow-x: visible;
		  @media (min-width: $lg) {
			width: calc(41.7% + 30px);
			margin-right: -30px;
		  }
		  @media (min-width: 1230px) {
			width: calc(41.7% + (50dvw - 585px));
			margin-right: calc((50dvw - 585px) * -1);
		  }

		  img {
			@media (min-width: $lg) {
			  position: absolute;
			  top: 0;
			  left: 0;
			  height: 100%;
			  aspect-ratio: 1440 / 500;
			  width: 100%;
			  object-fit: cover;
			}
		  }
		}
	  }
	}

	&--seo ,
	&--ar {
		margin-bottom: rem(110);

		font-size: rem(24);

		padding-inline: rem(30);
	}

	&--ar &__container {
		width: 100%;
		max-width: rem(1230);
		padding-right: rem(30);
		padding-left: rem(30);

		margin: 0 auto;
	}

	&--ar h2 {
		line-height: 1.1;
		color: $text-color-secondary;

		opacity: .5 !important;
	}

	&--ar &__text {
		padding-inline: 0;
	}

	&--ar &__text--option2 {
		margin-top: rem(40);
	}

	&--ar &__text--option2 &__p {
		max-width: rem(368);

		@media (max-width: $lg) {
			max-width: none;
		}
	}

	&--ar &__cols {
		max-width: rem(470);

		row-gap: rem(30);

		@media (max-width: $lg) {
			max-width: none;
		}
	}

	&--ar &__footer {
		margin-top: rem(120);

		font-family: $font-secondary;
		font-size: rem(60);
		text-align: center;

		@media (max-width: $lg) {
			margin-top: rem(60);

			font-size: rem(45);
		}

		@media (max-width: $md) {
			font-size: rem(34);
		}

		strong {
			color: $color-5;
		}
	}

	&--8th-wall &__text {
		margin-top: rem(115);

		font-weight: 200;
	}

	&--8th-wall &__cols {
		display: block;

		width: 100%;
		max-width: none;

		position: relative;
		margin-top: rem(13);

		font-size: rem(32);
	}

	&--8th-wall &__col {
		@media (max-width: $lg) {
			font-size: rem(18);
		}

		p {
			margin-top: rem(20);

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&--8th-wall &__decor {
		width: 55%;

		position: absolute;
		top: rem(-150);
		right: rem(-50);

		@media (max-width: $lg) {
			width: 75%;

			position: static;
			margin: 0 auto;
		}
	}

	&--web3 {
		margin-bottom: rem(48);
	}

	&--web3 h2 {
		line-height: 1.1;
	}

	&--web3 &__text {
		margin-top: rem(49);
	}

	&--web3 &__p {
		line-height: 1.58;

		&:last-child {
			max-width: rem(470);

			@media (max-width: $lg) {
				max-width: none;
			}
		}
	}

	&--nft {
		margin-bottom: rem(155);

		font-size: rem(32);

		@media (max-width: $lg) {
			margin-bottom: rem(100);
		}

		@media (max-width: $md) {
			margin-bottom: rem(75);
		}
	}

	&--nft &__text--option2 {
		margin-top: rem(80);

		@media (max-width: $lg) {
			margin-top: rem(40);
		}

		@media (max-width: $md) {
			margin-top: rem(20);
		}
	}

	&--nft &__text--option2 &__p {
		max-width: rem(600);

		@media (max-width: $lg) {
			max-width: none;
		}
	}

	&--nft &__cols {
		max-width: none;
	}

	&--nft &__images {
		display: flex;

		margin-top: rem(115);

		@media (max-width: $lg) {
			margin-top: rem(50);
		}

		@media (max-width: $md) {
			flex-direction: column;

			margin-top: rem(20);

			row-gap: rem(20);
		}
	}

	&--nft &__image {
		&:first-child {
			flex-basis: 57.8%;

			margin-top: rem(40);
		}

		&:nth-child(2) {
			flex-basis: 42.2%;
		}
	}

	&--ai {
		margin-bottom: rem(107);

		.s-pictureInformation__text {
			margin-top: rem(25);
		}

		.s-pictureInformation__footer {
			margin-top: rem(80);

			@media (min-width: $lg) {
				margin-top: rem(149);
			}
		}

		&.s-pictureInformation--8th-wall {
			margin-top: rem(80);

			@media (min-width: $lg) {
				margin-top: rem(185);
			}

			.s-pictureInformation__cols {
				margin-top: rem(5);
			}
		}
	}

	&--d-agency {
		margin-bottom: rem(43);

		.s-pictureInformation__text {
			max-width: 1211px;

			margin-top: rem(50);

			h2 {
				line-height: 1;
			}

			@media (min-width: $lg) {
				margin-top: 242px !important;
			}
		}

		.s-pictureInformation__cols {
			margin-top: rem(50);
		}
	}

  	&--shopify {
	  padding-inline: rem(125);
	  margin-top: rem(180);

	  @media (max-width: $lg) {
		padding-inline: rem(40);
		margin-top: rem(100);
	  }

	  @media (max-width: $md) {
		padding-inline: rem(20);
	  }
	  .subtitle {
		@media (min-width: $lg) {
		  font-size: rem(32);
		  font-weight: 1.6;
		}
	  }
	  .s-easyColumn {
		&__col {
		  margin-top: rem(64);
		  background-color: $color-3;
		  border-radius: rem(16);
		  padding: rem(40);
		  overflow: hidden;

		  @media (max-width: $md) {
			padding: rem(20);
			margin-top: rem(48);
		  }

		  * {
			color: #FFFFFF;
		  }

		  &__left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			p {
			  font-size: rem(32);
			  font-weight: 600;
			  max-width: rem(326);
			  line-height: 1.25;
			}
		  }

		  &__right {
			font-size: rem(18);
			.st-boldArrow--provideBlock {
			  padding-left: 0;
			  align-items: flex-start;
			  &:before {
				flex-shrink: 0;
				margin-top: rem(6.4);
			  }
			}
		  }
		}
	  }

	  #animation-container {
		width: rem(170);
		margin-left: rem(-48);
		margin-bottom: rem(-10);

		@media (max-width: $lg) {
		  display: none;
		}
	  }
	}
}

.s-pictureInformation__services {
	.s-pictureInformation__img {
		padding-left: rem(50);

		margin-inline: auto;
	}

	.s-pictureInformation {
		&__cols {
			column-gap: 3.5%;
		}

		&__col {
			width: 40%;

			&--last {
				width: 50%;
			}

			span {
				display: block;

				margin-left: 15px;
			}

			@media (max-width: $lg) {
				width: 100%;

				margin-bottom: rem(30);
			}
		}
	}

	.s-pictureInformation__text {
		margin-top: rem(200);

		padding-inline: rem(140);

		@media (max-width: $lg) {
			margin-top: rem(100);

			padding-inline: rem(20);
		}
	}

	&--shopify {
	  	position: relative;
	  	margin-bottom: 4.75rem;

		.s-pictureInformation__img--gut_health {
		  	position: absolute;
			right: 0;
			bottom: -16rem;
			z-index: 1;
		  	max-width: 35.875rem;
		  	max-height: 47.9375rem;
			width: 100%;
			height: 100%;

			@media (max-width: $xxl) {
			  max-width: 28.7rem;
			  max-height: 38.35rem;
			  bottom: -14rem;
			}

			@media (max-width: $lg) {
			  position: static;
			  margin-left: auto;
			  margin-right: calc((-100dvw + 730px - 2.5rem)/2);
			}

			@media (max-width: $md) {
			  max-width: 21.525rem;
			  max-height: 28.7625rem;
			  margin-right: calc((-100dvw + 575px - 2.5rem)/2);
			}

			@media (max-width: $sm) {
			  margin-right: -1.25rem;
			}
		}

	  	.s-pictureInformation__col--shopify{
			display: flex;
		  	flex-direction: column;
		  	justify-content: flex-end;

		  	.s-pictureInformation__img--shopify {
			  margin-bottom: -2rem;
			  margin-left: 8rem;

			  @media (max-width: $xl) {
				margin-left: 2rem;
			  }
			  @media (max-width: $lg) {
				margin-left: 0;
				margin-top: -2rem;
			  }
			}
		}
	}
}

.s-pictureInformation__joinus,
.s-pictureInformation__about {
	.s-pictureInformation__img {
		margin-inline: auto;
	}
}

.s-pictureInformation__about {
	margin-top: rem(200);

	.s-pictureInformation__img {
		width: 100%;

		img {
			width: 100%;
		}
	}
}

.mobile-no-padding {
	padding-inline: 0;
}

.transformation {
	&__img {
		margin-bottom: rem(0);
		margin-left: 80px;

		margin-inline: auto;

		// padding-left: rem(90);
		@media (max-width: 1300px) {
			margin-inline: auto;
		}

		@media (max-width: $lg) {
			padding-left: 0;

			padding-inline: 16px;
		}
	}

	&__img {
		&__text {
			display: flex;

			margin-top: rem(360);

			column-gap: rem(30);

			@media (max-width: $lg) {
				flex-direction: column;

				margin-top: rem(180);

				row-gap: rem(30);
			}
		}

		&__p {
			width: 50%;

			@media (max-width: $lg) {
				width: 100%;
			}
		}

		&__cols {
			flex-direction: column;

			width: 50%;
			padding: 0;

			margin: 0;

			row-gap: rem(40);

			@media (max-width: $lg) {
				width: 100%;
			}
		}

		&__col {
			width: 100%;

			margin-block: 0;
		}
	}

	&__wrapper {
		@media (max-width: 1350px) {
			img {
				transform: scale(.8);
			}
		}

		@media (max-width: $lg) {
			img {
				transform: scale(1);
			}
		}
	}

	&__img__1 {
		width: 23%;
		max-width: 267px;

		z-index: -1;
		top: -25%;
		left: -15%;

		@media (max-width: 1350px) {
			top: -15%;
			left: -5%;
		}

		@media (max-width: $lg) {
			z-index: 2;
			top: -40%;
			left: 10%;
		}
	}

	&__img__2 {
		width: 23%;
		max-width: 267px;

		z-index: -1;
		top: -10%;
		left: 97%;

		@media (max-width: 1350px) {
			left: 85%;
		}

		@media (max-width: 1080px) {
			left: 80%;
		}

		@media (max-width: $lg) {
			z-index: 2;
			top: -30%;
			left: 40%;
		}
	}

	&__img__3 {
		width: 30%;
		max-width: 267px;

		top: 32%;
		left: 80%;

		@media (max-width: 1350px) {
			top: 25%;
			left: 70%;
		}

		@media (max-width: $lg) {
			top: -20%;
			left: 70%;
		}
	}
}

//marketing
.marketing {
	&__img {
		margin-bottom: rem(0);
		margin-left: 180px;

		margin-inline: auto;

		// padding-left: rem(90);
		@media (max-width: 1300px) {
			margin-inline: auto;
		}

		@media (max-width: $lg) {
			padding-left: 0;

			padding-inline: 16px;
		}
	}

	&__img__1 {
		width: 30%;

		top: -43%;
		left: 68%;

		@media (max-width: $lg) {
			top: -20%;
		}
	}

	&__img__2 {
		width: 50%;
		max-width: 378px;

		top: 20%;
		left: 45%;

		@media (max-width: $lg) {
			display: none;
		}

		img {
			width: 100%;
		}
	}

	&__img__3 {
		width: 30%;

		top: 30%;
		left: -5%;

		@media (max-width: $lg) {
			top: -30%;
			left: 30%;
		}
	}

	&__img__4 {
		width: 50%;
		max-width: none;

		top: -10%;
		left: -10%;

		@media (max-width: 1050px) {
			left: -5%;
		}

		@media (max-width: $lg) {
			display: none;
		}
	}

	&__img__5 {
		width: 50%;
		max-width: none;

		top: 71%;
		left: 39%;

		@media (max-width: $lg) {
			display: none;
		}
	}
}

.iot__img {
	margin-left: auto;

	.transformation__wrapper img {
		transform: scale(1);
	}

	.transformation__img {
		&__1 {
			width: 30%;

			left: -30%;

			@media (max-width: $xl) {
				width: 25%;

				z-index: 5;
				top: -10%;
				left: 5%;
			}
		}

		&__2 {
			width: 30%;

			left: 100%;

			@media (max-width: $xl) {
				width: 25%;

				z-index: 5;
				top: -30%;
				left: 70%;
			}
		}

		&__3 {
			width: 30%;

			left: 65%;

			@media (max-width: $xl) {
				width: 25%;

				z-index: 5;
				top: -20%;
				left: 37.5%;
			}
		}
	}
}

.dma__img {
	@media (max-width: $xl) {
		margin-inline: auto;
	}

	.transformation__wrapper img {
		transform: scale(1);
	}

	.transformation__img {
		&__1 {
			z-index: 3;
			top: 2%;
			left: 105%;

			@media (max-width: $xl) {
				width: 25%;

				z-index: 5;
				top: -10%;
				left: 20%;
			}
		}

		&__2 {
			width: 30%;

			z-index: 2;
			top: 15%;
			left: 85%;

			@media (max-width: $xl) {
				width: 25%;

				z-index: 5;
				top: -30%;
				left: 60%;
			}
		}

		&__3 {
			width: 50%;

			top: 40%;
			left: 35%;

			@media (max-width: $xl) {
				display: none;
			}
		}

		&__4 {
			width: 100%;

			top: 75%;
			left: 35%;

			@media (max-width: $xl) {
				display: none;
			}
		}
	}
}

.marketing-hero {
	&__wrapper {
		width: 100%;
		max-width: 1200px;

		position: relative;

		aspect-ratio: 1.336 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 63.75%;

			z-index: 2;
			top: 15.37%;
			left: 15.42%;
		}

		&--2 {
			width: 18.75%;

			z-index: 4;
			top: 4.01%;
			left: 3.83%;
		}

		&--3 {
			width: 27%;

			z-index: 2;
			bottom: 0;
			bottom: 10%;
			left: 11.42%;
		}

		&--4 {
			width: 18.25%;

			top: 0;
			right: 0;
		}

		&--5 {
			width: 39.08%;

			z-index: 1;
			right: 6.92%;
			bottom: 4.9%;
		}

		&--6 {
			width: 39.08%;

			z-index: 3;
			top: 8.46%;
			left: 0;
		}
	}
}

.iot-sub-hero {
	align-items: center;

	padding: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1048px;

		position: relative;

		aspect-ratio: 1.105/1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 48.76%;

			top: 9.5%;
			left: 0;
		}

		&--2 {
			width: 22.52%;

			top: 0;
			right: 25.57%;
		}

		&--3 {
			width: 22.52%;

			right: 0;
			bottom: 12%;
		}

		&--4 {
			width: 38.17%;

			right: 25.57%;
			bottom: 0;
		}
	}
}

.sharepoint-sub-hero {
	align-items: center;

	&__wrapper {
		width: 100%;
		max-width: 920px;

		position: relative;

		aspect-ratio: 1.325/1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 72.93%;

			top: 0;
			left: 0;
		}

		&--2 {
			width: 23.48%;

			top: 13.4%;
			right: 0;
		}

		&--3 {
			width: 29.13%;

			right: 27.17%;
			bottom: 0;
		}
	}
}

.metaverse-sub-hero {
	align-items: center;

	padding: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1210px;

		position: relative;

		aspect-ratio: 1.486/1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 55.29%;

			top: 0;
			left: 0;
		}

		&--2 {
			width: 21.32%;

			top: 6.88%;
			right: 20.74%;
		}

		&--3 {
			width: 32.81%;

			bottom: 5.9%;
			left: 22.48%;
		}

		&--4 {
			width: 42.07%;

			right: 0;
			bottom: 0;
		}
	}
}

.internal-sub-hero {
	align-items: center;

	padding: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1237px;

		position: relative;

		aspect-ratio: 1.436 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 59.66%;

			top: 0;
			left: 0;
		}

		&--2 {
			width: 46%;

			bottom: 0;
			left: 5.42%;
		}

		&--3 {
			width: 46%;

			right: 0;
			bottom: 17.42%;
		}
	}
}

.digitalproductdevelopment-sub-hero {
	display: flex;
	justify-content: center;

	margin: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1316px;

		position: relative;

		aspect-ratio: 1.651192 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 30.4%;

			top: 0;
			left: 1.82%;
		}

		&--2 {
			width: 60.94%;

			top: 0;
			right: 0;
		}

		&--3 {
			width: 17.1%;

			bottom: 0;
			left: 0;
		}

		&--4 {
			width: 17.1%;

			bottom: 4.14%;
			left: 19.6%;
		}
	}
}

.digitalproductdevelopment-sub-hero-mobile {
	display: flex;
	justify-content: center;

	&__wrapper {
		width: 100%;

		position: relative;

		aspect-ratio: .7 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 41.33%;

			z-index: 2;
			top: 0;
			left: 4.27%;
		}

		&--2 {
			width: 41.33%;

			z-index: 2;
			top: 15.38%;
			right: 4.27%;
		}

		&--3 {
			width: 100%;

			z-index: 1;
			bottom: 0;
			left: 0;
		}
	}
}

.digitaltransformation-sub-hero {
	display: flex;
	justify-content: center;

	margin: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1206px;

		position: relative;

		aspect-ratio: 1.321 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 33.17%;

			top: 0;
			left: 1.41%;
		}

		&--2 {
			width: 63.43%;

			top: 7.89%;
			right: 0;
		}

		&--3 {
			width: 17.99%;

			bottom: 12.49%;
			left: 0;
		}

		&--4 {
			width: 17.99%;

			bottom: 8.11%;
			left: 20.73%;
		}

		&--5 {
			width: 38.89%;

			right: 19.9%;
			bottom: 0;
		}
	}
}

.services-sub-hero {
	display: flex;
	justify-content: center;

	margin: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1206px;

		position: relative;

		aspect-ratio: 1.967 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 59.94%;

			bottom: 0;
			left: 0;
		}

		&--2 {
			width: 17.64%;

			top: 0;
			right: 20.03%;
		}

		&--3 {
			width: 17.64%;

			right: 0;
			bottom: 2.94%;
		}
	}
}

.digitalmarketingagency-sub-hero {
	display: flex;
	justify-content: center;

	margin: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 926px;

		position: relative;

		aspect-ratio: 1.299 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 16.85%;

			top: 5.75%;
			left: 0;
		}

		&--2 {
			width: 34.99%;

			z-index: 3;
			top: 0;
			left: 19.44%;
		}

		&--3 {
			width: 24.41%;

			bottom: 1.4%;
			left: 20.52%;
		}

		&--4 {
			width: 25.49%;

			z-index: 2;
			right: 26.35%;
			bottom: 0;
		}

		&--5 {
			width: 43.2%;

			z-index: 1;
			top: 0;
			right: 0;
		}
	}
}

.mobileappdevelopers-sub-hero {
	display: flex;
	justify-content: center;

	margin: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1044px;

		position: relative;

		aspect-ratio: 1.066 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 22.63%;

			bottom: 5.11%;
			left: 0;
		}

		&--2 {
			width: 22.63%;

			z-index: 3;
			bottom: 23.93%;
			left: 25.7%;
		}

		&--3 {
			width: 22.63%;

			top: 0;
			right: 25.7%;
		}

		&--4 {
			width: 22.05%;

			z-index: 2;
			right: 25.7%;
			bottom: -6%;
		}

		&--5 {
			width: 22.63%;

			z-index: 1;
			right: 0;
			bottom: 17.18%;
		}
	}

	&--web3 &__wrapper {
		max-width: 1154px;

		aspect-ratio: 1.38 / 1;
	}

	&--web3 &__image {
		&--1 {
			width: 20.63%;

			bottom: 18.231%;
		}

		&--2 {
			width: 20.93%;

			bottom: 27.931%;
			left: 26.2%;
		}

		&--4 {
			width: 20.75%;

			right: 26.6%;
			bottom: 18.1%;
		}

		&--5 {
			width: 21.23%;

			right: 0;
			bottom: 21.98%;
		}
	}

	&--digital &__wrapper {
		max-width: 1000px;

		aspect-ratio: 1.066 / .8;
	}

	&--digital &__image {
		&--1 {
			width: 22.5%;

			bottom: 20.11%;
		}

		&--2 {
			width: 22.5%;

			bottom: 4.93%;
			left: 26.2%;
		}

		&--4 {
			width: 22.5%;

			right: 26%;
			bottom: 31%;
		}

		&--5 {
			width: 22.5%;

			right: 0;
			bottom: -4.82%;
		}
	}

	&--digital + .l-container {
		padding-right: 20px;
		padding-left: 20px;
	}

	&--digital + .s-pictureInformation__text .s-pictureInformation__cols {
		max-width: rem(940);
	}
}



.web-design-sub-hero {
	display: flex;
	justify-content: center;

	margin: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1240px;

		position: relative;

		aspect-ratio: 1.127 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 32.26%;

			top: 27.55%;
			left: 0;
		}

		&--2 {
			width: 18.15%;

			z-index: 3;
			bottom: 0;
			left: 7.1%;
		}

		&--3 {
			width: 59.52%;

			top: 6.27%;
			left: 19.76%;
		}

		&--4 {
			width: 59.52%;

			z-index: 2;
			bottom: 5.82%;
			left: 27.82%;
		}

		&--5 {
			width: 18.15%;

			z-index: 1;
			top: 0;
			right: 0;
		}
	}
}

.healthcare-sub-hero {
	display: flex;
	align-items: center;

	padding: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1250px;

		position: relative;

		aspect-ratio: 1.61 / 1;

		@media screen and (min-width: 992px) {
			left: -1rem;
		}
	}

	&__image {
		position: absolute;

		&--1 {
			width: 18%;

			z-index: 3;
			top: 8.25%;
			left: 0;
		}

		&--2 {
			width: 37.52%;

			z-index: 2;
			bottom: 0;
			left: 9.68%;
		}

		&--3 {
			width: 59.04%;

			z-index: 3;
			top: 0;
			left: 20.56%;
		}

		&--4 {
			width: 18%;

			z-index: 3;
			bottom: 9.79%;
			left: 59.52%;
		}

		&--5 {
			width: 18%;

			z-index: 1;
			top: 8.25%;
			right: 0;
		}
	}
}

.chatbots-sub-hero {
	display: flex;
	justify-content: center;

	padding: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 1170px;

		position: relative;

		aspect-ratio: 1.351 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 43.85%;

			z-index: 1;
			bottom: 19.23%;
			left: 0;
		}

		&--2 {
			width: 43.68%;

			z-index: 3;
			top: 7.85%;
			right: 21.97%;
		}

		&--3 {
			width: 50.51%;

			z-index: 2;
			right: 7.09%;
			bottom: 0;
		}

		&--4 {
			width: 19.23%;

			z-index: 3;
			top: 0;
			right: 0;
		}
	}
}

.soogleanalitics-sub-hero {
	display: flex;
	justify-content: center;

	padding: 0 1rem;

	&__wrapper {
		width: 100%;
		max-width: 970px;

		position: relative;

		aspect-ratio: 1.187 / 1;
	}

	&__image {
		position: absolute;

		&--1 {
			width: 68.97%;

			top: 0;
			left: 0;
		}

		&--2 {
			width: 48.35%;

			bottom: 0;
			left: 20.52%;
		}

		&--3 {
			width: 27.84%;

			top: 23.13%;
			right: 0;
		}
	}
}

.desktop {
	@media (max-width: $lg) {
		display: none;
	}
}

.mobile {
	display: none;

	@media (max-width: $lg) {
		display: block;
	}
}

.box-shadow {
	&--20 {
		box-shadow: 0 10px 20px rgba(0, 0, 0, .25);
	}
}

.border-radius {
	&--20 {
		border-radius: 20px;
		overflow: hidden;

		@media (max-width: $lg) {
			border-radius: 0;
		}
	}
}

.image {
	&--100 {
		width: 100%;
		height: auto;
	}
}

.s-pictureInformation__images {
  margin-top: rem(80);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .image-item__wrapper {
	position: relative;
	width: 100%;
  }

  &--item-left {
	.image-item__wrapper {
	  padding-bottom: 77.93%;
	}
  }
  &--item-right {
	.image-item__wrapper {
	  margin-top: rem(45);
	  padding-bottom: 80.88%;
	}
  }

  &--item-left,
  &--item-right {
	width: 48%;

	@media (max-width: $md) {
	  width: 100%;
	}

	img {
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left: 0;
	  top: 0;
	  object-fit: cover;
	}
  }
}
