.s-doubleImageWithCaption {
	display: flex;
	flex-direction: column;
	justify-content: end;

	margin-top: rem(60);
	margin-bottom: rem(105);

	@media (min-width: $md) {
		flex-direction: row;

		margin-top: rem(160);
		margin-bottom: rem(175);
	}

	&--health {
		.s-doubleImageWithCaption__image {
			&:first-of-type {
				width: 40%;
			}

			&:last-of-type {
				width: 60%;
			}

			img {
				height: rem(500);
			}

			@media (max-width: $md) {
				width: 100% !important;
				&:first-of-type {
					margin-bottom: rem(80);
				}
			}
		}
	}

	&--events {
		.s-doubleImageWithCaption__image {
			&:first-of-type {
				width: 45%;
			}

			&:last-of-type {
				width: 55%;
			}

			img {
				height: rem(500);
			}

			@media (max-width: $md) {
				width: 100% !important;
				&:first-of-type {
					margin-bottom: rem(80);
				}
			}
		}
	}

	&--events-reverse {
		.s-doubleImageWithCaption__image {
			&:first-of-type {
				width: 60%;
			}

			&:last-of-type {
				width: 40%;
			}

			img {
				height: rem(500);
			}

			@media (max-width: $md) {
				width: 100% !important;
				&:first-of-type {
					margin-bottom: rem(80);
				}
			}
		}
	}

	&--vr {
		@media (min-width: $md) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&--product-visualisation {
		margin-top: 0;

		@media (max-width: $md) {
			margin-bottom: rem(55);
		}

		.s-doubleImageWithCaption__image {
			width: 50%;

			img {
				height: rem(440);
			}

			@media (max-width: $md) {
				width: 100% !important;
				&:first-of-type {
					margin-bottom: rem(80);
				}
			}
		}
	}

	.s-doubleImageWithCaption__image {
		display: block;

		height: rem(500);

		img {
			width: 100%;

			object-fit: cover;
			-o-object-fit: cover;
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		margin-top: rem(27);

		font-family: $font-base;
		font-size: rem(18);
		font-weight: 700;
		line-height: rem(24);
		text-align: center;
		color: #D1D1D6;
	}
}
