/**
 * Full image section.
 */
.full-image {
    width: 100%;
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        display: block;
    }
}
