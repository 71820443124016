.arrow-text {
  line-height: 1.2;
  margin-top: rem(12);
  font-size: rem(13);
  padding-left: 0!important;
  align-items: flex-start;

  & strong {
	font-weight: 600;
  }

  &:before {
	width: rem(14);
	height: rem(14);
	margin-right: rem(8);
	margin-top: rem(4);
  }
}
