.hero-seo {
  aspect-ratio: 1451 / 968;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/seo-content-optimization.jpg");
  margin-bottom: calc((88 / 1440* 100dvw) * -1);

  @supports (background-image: url("../img/seo-content-optimization.webp")) {
	background-image: url("../img/seo-content-optimization.webp");
  }
  &__content {
	height: 100%;
	width: 100%;
	display: flex;
  }
  &__title {
	margin-top: auto;
	margin-bottom: 20.5%;
	padding-left: 14.5%;
  }

  .st-scaleHeading {
	&--medium {
	  line-height: 1.2;
	  font-weight: 900;
	  font-style: normal;
	  font-size: calc(70 / 1440 * 100dvw)!important;
	}

	&--large {
	  line-height: 1.2;
	  font-weight: 900;
	  font-style: normal;
	  font-size: calc(210 / 1440 * 100dvw);
	  margin-bottom: calc((20 / 1440 * 100dvh) * -1);

	  @media (max-width: $md) {
		margin-bottom: 0;
	  }
	}
  }
}

.hero-web-analytics {
  aspect-ratio: 1440 / 1046;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/web-analytics.png");

  @supports (background-image: url("../img/web-analytics.webp")) {
	background-image: url("../img/web-analytics.webp");
  }
  &__content {
	height: 100%;
	.l-container {
	  margin-bottom: 0;
	  height: 100%;
	}
	&--wrapper {
	  margin-bottom: calc(37 / 1440 * 100dvw)!important;;
	  max-width: rem(925);
	  display: flex;
	  flex-direction: column;
	  justify-content: flex-end;
	  margin-left: 0;
	  height: 100%;
	}
  }

  .st-scaleHeading {
	&--medium {
	  line-height: 1;
	  font-weight: 900;
	  font-style: normal;
	  font-size: calc(177 / 1440 * 100dvw)!important;
	}

	&--large {
	  line-height: 1;
	  font-weight: 900;
	  font-style: normal;
	  font-size: calc(220 / 1440 * 100dvw);
	  margin-bottom: calc((26 / 1440 * 100dvh) * -1);

	  @media (max-width: $md) {
		margin-bottom: 0;
	  }
	}
	&--subheading {
	  font-size: calc(70 / 1440 * 100dvw);
	  font-style: normal;
	  font-weight: 200;
	  line-height: 110%;

	  @media (max-width: $lg) {
		line-height: 120%;
	  }

	  @media (max-width: $md) {
		line-height: 130%;
	  }
	}
  }
}

.hero-paid-media {
  aspect-ratio: 1685 / 968;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/paid-media-886.png");
  box-shadow: 0 rem(4) rem(4) 0 rgba(0, 0, 0, 0.25);
  margin-bottom: calc((50 / 1440 * 100dvw) * -1);

  @supports (background-image: url("../img/paid-media-886.webp")) {
	background-image: url("../img/paid-media-886.webp");
  }

  &__content {
	height: 100%;

	.l-container {
	  margin-bottom: 0;
	  height: 100%;
	  display: flex;
	  align-items: flex-start;
	  justify-content: center;
	}
  }

  .st-scaleHeading {
	&--medium {
	  line-height: 1;
	  font-weight: 900;
	  font-style: normal;
	  font-size: calc(180 / 1440 * 100dvw)!important;
	  margin-bottom: calc((36 / 1440 * 100dvw) * -1);
	}

	&--large {
	  line-height: 1;
	  font-weight: 900;
	  font-style: normal;
	  font-size: calc(220 / 1440 * 100dvw);
	}

  }
}
.hero-seo,
.hero-web-analytics,
.hero-paid-media {
  &__title {
	span {
	  display: block;
	}
  }
}

.hero-video {
  aspect-ratio: 1440 / 800;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-image: url("../img/br-only-hero-bg.png");
  margin-bottom: calc(190 / 1440 * 100dvw);

  @media (max-width: $md) {
	margin-bottom: calc(320 / 1440 * 100dvw);
  }

  @supports (background-image: url("../img/br-only-hero-bg.webp")) {
	background-image: url("../img/br-only-hero-bg.webp");
  }

  img {
	display: block;
	position: relative;
	z-index: 1;
	aspect-ratio: 1168 / 616;
	width: 81.1%;
	margin-top: auto;
	margin-bottom: calc((134 / 1440 * 100dvw) * -1);

	@media (max-width: $md) {
	  margin-bottom: calc((250 / 1440 * 100dvw) * -1);
	  max-width: calc(100dvw - 2rem);
	  width: 100%;
	}
  }

  h1 {
	top: calc(170 / 1440 * 100dvw);
	left: calc(101 / 1440 * 100dvw);
	position: absolute;

	svg {
	  max-height: calc(210 / 1440 * 100dvw);
	  width: 100%;
	}
  }

  &__content {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;

  }

  + section {
	margin-bottom: 5.625rem;

	@media (max-width: $lg) {
	  margin-bottom: 0;
	}
  }
}
