.st-scaleHeading {
  font-size: rem(180);
  @media (max-width: $xl) {
    font-size: rem(120);
  }
  @media (max-width: $md) {
    font-size: rem(70);
  }
  @media (max-width: 600px) {
    font-size: rem(120);
    white-space: nowrap;
    display: inline-block;
  }
}

.st-scaleHeading--medium {
  font-size: rem(120);
  @media (max-width: $xl) {
    font-size: rem(90) !important;
  }
  @media (max-width: $md) {
    font-size: rem(48) !important;
    word-wrap: break-word;
  }
}

.st-scaleHeading--normal {
  font-size: rem(72);
  list-style: none;
  line-height: 1.2;
  letter-spacing: rem(-0.9);
  @media (max-width: $xl) {
    font-size: rem(50) !important;
    line-height: 1 !important;
  }
  @media (max-width: $md) {
    font-size: rem(32) !important;
    word-wrap: break-word;
  }
}
