.s-numbers {
  margin-top: rem(95);
  &__ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(20);
    row-gap: rem(50);

    @media (max-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      font-size: rem(18);
      @media (max-width: $lg) {
      }
      span {
        display: block;
        font-size: rem(48);
        font-weight: 900;
      }
    }
  }
}
