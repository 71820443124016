.video {
  &__header {
	position: absolute;
	color: rgb(0, 0, 0);
	margin-top: rem(500);
	opacity: 0;
	animation: videoHeader 1s ease forwards;
  }

  &__container {
	display: flex;
	align-items: center;
	justify-content: center;
  }

  &__wrap {
	transform: translateY(-200px);
	animation: showNav 1s ease-in-out forwards;
  }

  &__anim {
	position: absolute;
	width: 100%;
	border-radius: rem(60);
	overflow: hidden;
	margin-top: 0;
	object-fit: cover;
	height: calc(100% - rem(100));
	z-index: 900;
	transform: scale(1.2);
	animation: scaleVideo 1s ease-in-out forwards;
	padding-inline: rem(20);

	+ h1 {
	  position: absolute;
	  animation: none;
	}
  }
}

#vimeo-custom {
  &-tab {
	width: rem(300);
	position: absolute;
	bottom: rem(16);
	right: 0;
	border: 1px solid #555555;
	border-right: none;
	background: $color-1;
	padding: rem(12) rem(16);
	z-index: 59;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	@media (max-width: $lg) {
	  width: 100%;
	  bottom: 0;
	  padding: rem(8);
	  margin-top: rem(80);
	  border-right: 1px solid #555555;
	}

	&__text {
	  padding-left: rem(6);

	  @media (max-width: $lg) {
		display: flex;
		column-gap: rem(8);
		padding-left: 0;
	  }

	  @media (max-width: $sm) {
		* {
		  font-size: rem(14);
		}
	  }


	  br {
		@media (max-width: $lg) {
		  display: none;
		}
	  }
	}

	&__video {
	  position: relative;

	  #open-vimeo-modal {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: rem(36);
		width: rem(36);

	  }

	  img {
		object-fit: cover;
		@media (max-width: $lg) {
		  width: rem(100);
		  height: rem(55);
		}
	  }
	}

	svg {
	  width: rem(14);
	  height: rem(14);
	}

	p {
	  font-size: rem(18);
	  line-height: 1.3;
	  font-weight: 400;
	}
  }
  &-video {
	position: fixed;
	top: 0;
	left: 0;
	height: 100dvh;
	width: 100dvw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000000E6;
	z-index: -1;
	opacity: 0;
	transition: all ease-in-out 0.3s;

	&.active {
	  opacity: 1;
	  z-index: 99;
	}

	.l-container {
	  position: relative;
	  padding-left: 1.875rem;
	  padding-right: 1.875rem;
	  max-width: min(90rem, 160dvh);

	  @media (max-width: $lg) {
		padding-left: 1rem;
		padding-right: 1rem;
	  }
	}

	#close-vimeo-modal {
	  position: absolute;
	  top: rem(-48);
	  right: rem(20);
	  padding: rem(12);

	  @media (max-width: $lg) {
		padding: rem(6);
		right: rem(4);
		top: rem(-54);
	  }
	}
  }
}

/*.vimeo-spacing-mobile {
  @media (max-width: $lg) {
	padding-top: rem(80);
  }
 !* header {
	@media (max-width: $lg) {
	  top: rem(80);
	}
  }*!
}*/

@keyframes videoHeader {
  0% {
	margin-top: rem(500);
	opacity: 0;
  }
  40% {
	opacity: 0.5;
  }
  75% {
	opacity: 1;
  }
  100% {
	opacity: 1;
	margin-top: initial;
  }

}

@keyframes scaleVideo {
  0% {
	z-index: 900;
	margin-top: 0;
	transform: scale(1.2);
  }
  100% {
	z-index: 900;
	margin-top: rem(100);
	transform: scale(1);
  }
}

@keyframes showNav {
  0% {
	transform: translateY(-200px);
  }
  100% {
	transform: translateY(0);
  }
}
