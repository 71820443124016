/**
 * Contact section.
 */

.js-contact {
  &.sent {
    & > .InputBox {
      display: none !important;
    }

    .s-contact {
      &__group {
        display: none;
      }
    }
  }

  &-form {
    .InputBox {
      @media (max-width: $lg) {
        display: none;
      }
    }
  }
}

.s-contact {
  position: relative;
  padding-bottom: 6%;
  padding-top: 8%;

  &__box {
    position: relative;
  }
  &__about {
    margin-top: rem(180);
  }
	&__buttons {
		.s-contact-buttons {
			background-color: transparent;
			&__container {
				justify-content: start;
				padding: rem(24) 0;
			}
			a {
				text-decoration: none;
			}
			.c-btn--whatsapp {
				border-color: #C2FACB;
				.whatsapp {
					background-color: #C2FACB;
				}
			}
		}
	}
  // Text box.
  .c-box {
    position: relative;
    margin-bottom: rem(40);
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $lg) {
      margin-bottom: 24px;
    }
    @media (max-width: $md) {
      margin-bottom: rem($spacing-mobile);
    }
		.s-contact__buttons {
			h2 {
				margin-bottom: 0;
				margin-top: rem(36);
			}
		}
    h2 {
      margin-top: 0;
      margin-bottom: rem(12);
    }
	nav & {
	  max-width: unset;
	}
  }
  /**
	 * Contact form stuff.
	 */
  &__wrapper {
    display: flex;
    min-height: 1px;
    @media (max-width: $lg) {
      flex-direction: column;
    }
  }
  &__group {
    width: 50%;
    flex: 1;
    @media (max-width: $lg) {
      width: 100%;
    }
    // Submit button group.
    &:nth-last-child(1) {
      margin-bottom: 0;
      text-align: right;
      @media (max-width: $lg) {
        text-align: left;
      }
    }
    .show-confirmation & {
      display: none;
    }

    .s-contact__header & {
      &:nth-last-child(2) {
        flex: 0 1 40.69%;
        margin-right: rem(32);

        input {
          width: 100%;
        }
      }
      &:nth-last-child(1) {
        flex: 1;
      }
    }
  }
  label {
    font-family: $font-secondary;
    display: block;
  }
  input[type="text"],
  input[type="email"] {
    box-sizing: border-box;
    width: 80%;
    padding: rem(13) 0;
    padding-left: rem(24);
    padding-right: rem(24);
    font-size: rem(18);
    border:none;
    border-bottom: 2px solid #fff;
    border-radius: rem(0);
    @media (max-width: $lg) {
      width: 100%;
      margin-inline: 0;
    }
    // @media (max-width: $md) {
      // padding: rem(16) rem(24);2
    // }
  }
  textarea {
    padding: rem(16) rem(24);
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    background: transparent;
    border-bottom: 2px solid #fff;
    color: #fff;
    font-size: rem(18);
    width: 100%;
    height: rem(137);
    margin-bottom: rem(20);
	/*border-radius: rem(20);*/
	border-radius: rem(0);
    transition: background-color .8s;
  }
  &__response-output {
    display: none;
    font-size: rem(18);
    .show-confirmation & {
      display: block;
    }
  }

  .c-btn {
    position: relative;

    &__button {
      @media (max-width: $lg) {
        margin-top: 20px;
      }
    }

    @media (max-width: $lg) {
      padding-top: 6.5px;
      padding-bottom: 6.5px;
    }

    &.loading {
      pointer-events: none;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        right: -2px;
        left: -2px;
        bottom: -2px;
        background-color: rgba(#ffffff, 0.95);
        border-radius: 26px;
        z-index: 2;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml, %3Csvg class='s-contact__loader' version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%23000000' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
}

.s-contact__services {
  margin-top: rem(170);
}

.s-contact__header {
  margin: 0;
  padding: 0;
  background: transparent;
  width: 90%;
  max-width: rem(969);
  display: none;

  @media (max-width: $lg) {
    position: absolute;
    background-color: $color-3;
    width: 100%;
    top: 0%;
    left: 0;
    padding-top: rem(160);
    transform: translateX(100%);
    z-index: 5;
    height: 100vh;
    min-height: 700px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
  &__boxes {
    padding-inline: 16px;
    margin: 0;
  }
}

.s-contact__header__left {
  flex: none;
  width: 36%;
  margin-right: rem(10);
  @media (max-width: $lg) {
    width: 100%;
  }
  input {
    width: 100%;
  }
}

.s-contact__header__right {
  flex: none;
  width: 55%;
  @media (max-width: $lg) {
    width: 100%;
  }
  textarea {
    margin-bottom: rem(30);
  }
}

input:focus {
  background-color: #fff;
  color: #000;
}

input {
  transition: background-color .8s;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background: inherit;
}

.Input,
.textarea {
  padding: 0.5em;
  border: 0.2em solid;
  border-radius: 0.4em;
  position: relative;
  z-index: 2;
  &:focus,
  &:not(:placeholder-shown),
  &:-webkit-autofill {
    background-color: #fff;
    & + label {
      font-size: 0.8rem;
      left: rem(16);
      top: 0;
      transform: translateY(0) translateX(10);
      transition: 0.2s ease-in-out;
      color: grey;
      z-index: 5;
    }
  }
  &Wrapper {
    display: flex;
    flex-flow: column;
    position: relative;
    margin: 0 0 rem(24);
    input {
      width: 100%;
    }
  }
  &Label {
    position: absolute;
    top: 50%;
    left: rem(24);
    transform: translateY(-50%);
    @media (max-width: $lg) {
      top: 28px;
    }
  }

  &:not(:placeholder-shown) {
    background-color: #fff;
    color: #000;
  }
}

.textarea {
  &Label {
    position: absolute;
    top: 10%;
    left: rem(24);
    transform: translateY(-50%);

    @media (max-width: $lg) {
      top: 35px;
    }
  }
}

textarea:focus {
  background-color: #fff;
  color: #000;
}

.s-contact__box {
  position: relative;
}

.contact__bg {
  position: absolute;
  z-index: -1;
  &--1 {
    top: -50%;
    left: -90%;
    right: 0;
    transform: scale(1.5);
    animation: 7s infinite gradientTransform-contact;

    @media (max-width: $lg) {
      top: 30%;
      animation: initial;
      left: 0;
      transform: scale(3);
      z-index: -1;
    }
    @media (max-width: $md) {
      top: 15%;
    }
  }
  &--2 {
    top: -80%;
    left: -30%;
    transform: scale(1.8);
    z-index: -1;
    @media (max-width: $lg) {
      top: 30%;
      transform: scale(3);
      left: 0;
    }
  }
  &--3 {
    bottom: -90%;
    right: -80%;
    @media (max-width: $lg) {
      top: 100%;
      left: 0;
      transform: scale(3);
    }
  }
}

.div-step {
  margin-top: rem(40);
  display: none;

  @media (max-width: $lg) {
    display: flex;
  }

  a {
    text-decoration: none;
  }
}
.InputBox {
  @media (min-width: $lg) {
    display: block !important;
  }

  &-heading {
    margin-bottom: rem(32);
    font-weight: 700;
    display: none;

    @media (max-width: $lg) {
      display: block;
    }
  }
}
.input-invalid {
  font-size: rem(14);
  color: #ffffff;
  display: none;
  position: relative;
  margin-top: 4px;
}

// Animation

@keyframes gradientTransform-contact {
  0% {
    transform: translateX(0) scale(1.5);
  }
  50% {
    transform: rotate(-20deg) translateX(25%) translateY(20%) scale(1.5);
  }
  100% {
    transform: translateX(0) scale(1.5);
  }
}

.s-contact__ecommerce{
  padding-bottom: 4.5%;
}

.s-contact__d-agency{
  padding-bottom: 4.5%;
  padding-top: 3%;
}
