.short-slider {
	&__title {
		display: flex;

		margin-bottom: rem(20);

		gap: 25px;
		@media (max-width: $lg) {
			flex-direction: column;
		}
		&__heading {
			width: 50%;

			font-size: rem(72);

			@media (max-width: $lg) {
				width: 100%;
			}

			@media (max-width: $md) {
				font-size: rem(40);
			}
		}
		&__text {
			max-width: 470px;
		}
	}

	&--ar {
		margin-top: rem(200);
		margin-bottom: rem(100);

		@media (max-width: $lg) {
			margin-top: rem(100);
		}
	}

	&--product-visualisation {
		margin-top: 0;
	}

	&--ai {
		margin-top: rem(80);
		margin-bottom: rem(90);

		@media (min-width: $lg) {
			margin-top: rem(164);
			margin-bottom: rem(90);
		}

		.short-slider__title__heading {
			letter-spacing: rem(-1);
		}
		.short-slider__title {
			margin-bottom: rem(30);

			gap: 12px;
		}
	}

	&--web3 {
		margin-top: rem(160);
		margin-bottom: rem(185);
	}

	&-d--agency {
		.leadership-short-slider {
			@media (min-width: $lg) {
				padding: rem(0) rem(20);
			}
			@media (min-width: $xl) {
				padding: 0;
			}
		}
	}
}

.leadership-short-slider {
	width: 100%;
	max-width: 1160px;

	margin: 0 auto;
}
