@keyframes changeColor {
  to {
    opacity: 1;
  }
}

@keyframes changeSize {
  to {
    width: 100%;
  }
}
