.c-quote {
  width: 100%;
  display: flex;
  column-gap: rem(45);
  margin-top: rem(105);
  @media (max-width: $lg) {
    flex-direction: column-reverse;
  }
  &__left {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-top: rem(5);
    margin-left: rem(20);
    gap: rem(40);
    @media (max-width: $lg) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__right {
    width: 57%;
    padding-right: rem(0);
    font-size: rem(32);
    font-weight: 100;
    @media (max-width: $lg) {
      width: 100%;
      margin-bottom: rem(50);
    }
    span {
      font-size: rem(18);
      font-weight: 700;
      display: block;
    }
  }
  &__name {
    margin-top: rem(24);
  }
  &__profession {
    color: #ed1442;
  }
}

.about__quote {
  margin-bottom: rem(400);
  @media (max-width: $lg) {
    margin-bottom: rem(200);
  }
}
