.flare-animation {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    aspect-ratio: 1/1;
    @media (max-width: $xl) {
        top: -80%;
    }
    @media (max-width: $lg) {
        top: -40%;
    }
    @media (max-width: $md) {
        top: -30%;
        transform: scale(1.2);
    }
    @media (max-width: $sm) {
        top: -20%;
    }
    @media (max-width: $xs) {
        top: -15%;
    }
    &--element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
    &--1 {
        top: 30%;
        z-index: 4;
        left: 40%;
        animation: flare1 10s ease-in-out infinite;
        z-index: 7;
        // display: none;
    }
    &--2 {
        top: 5%;
        z-index: 8;
        left: 50%;
        animation: flare2 10s ease-in-out infinite;
        z-index: 10;
    }
    &--3 {
        left: -10%;
        z-index: 5;
        animation: flare3 10s ease-in-out infinite;
        // display: none;
    }
    &--4 {
        top: 33%;
        left: -30%;
        z-index: 3;
    }
    &--5 {
        top: 25%;
        z-index: 6;
        left: 40%;
        animation: flare4 10s ease-in-out infinite;
        z-index: 7;
    }
}

@keyframes flare1 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-20%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes flare2 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-54%, 30%);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes flare3 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: rotate(328deg) translate(0, 8%);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes flare4 {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    40% {
        transform: translate(-30%, 10%);
        opacity: 1;
    }
    50% {
        transform: translate(-20%, 8%);
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}
