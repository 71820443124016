.column-imgs {
  padding-bottom: rem(96);
  padding-top: rem(200);
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media (max-width: $lg) {
    padding-bottom: rem(70);
    padding-top: rem(70);
  }
  &__title {
    max-width: 100%;
    white-space: initial;
  }
  &__col {
    margin-top: rem(61);
    display: flex;
    gap: rem(32);
    font-size: rem(18);
    line-height: rem(28.8);
    @media (max-width: $lg) {
      flex-direction: column;
      gap: rem(0);
      margin-top: rem(40);
    }
    p {
      margin: 0 0 rem(30);
    }
    &__left {
      width: 31.46%;
      font-weight: 100;

      @media (max-width: $lg) {
        width: 100%;
      }
    }
    &__right {
      width: 49%;
      @media (max-width: $lg) {
        width: 100%;
      }
    }
  }

  &__btn {
    display: inline-block;
  }

  &__imgs {
    margin-top: rem(87);
    display: flex;
    gap: rem(36);
    align-items: flex-start;
    @media (max-width: $lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__img {
    border-radius: rem(12);
    overflow: hidden;
    &--1 {
      flex: 0 0 48%;
      @media (max-width: $lg) {
        flex: 0 0 100%;
      }
    }
    &--2 {
      flex: 0 0 31.4%;
      margin-top: rem(249.5);
      @media (max-width: $lg) {
        flex: 0 0 100%;
        margin-top: 0;
      }
    }
    img {
      width: 100%;
    }
  }
  &__bg {
    position: absolute;
    bottom: 0;
    left: 58.5%;
    width: rem(507);
    z-index: -1;
    @media (max-width: $lg) {
      display: none;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
