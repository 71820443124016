.s-project__info {
  color: $color-2;
  padding-top: rem(220);
  padding-bottom: rem(160);
  font-weight: 100;
  position: relative;
  @media (max-width: $lg) {
    padding-top: rem(56);
    padding-bottom: rem(96);
    padding-inline: rem(16);
  }
  &--papajohnes {
    background: $papajohns-green;
  }
  &--hp {
    background-color: $hp-blue;
  }
  &--snickers {
    background-color: $snickers-3;
  }
  &--compete {
    background-color: $compete-1;
    border-radius: 40px;
  }
  &--texaco {
    background-color: $texaco-2;
    padding-top: rem(208);
    padding-bottom: rem(320);
    border-radius: 0 0 rem(40) rem(40);
    @media (max-width: $lg) {
      padding-block: rem(56);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $texaco-1;
      z-index: -1;
    }
  }
  &--baby {
    background-color: $baby-1;
    color: $background;
    padding-bottom: rem(51);
    z-index: 2;
  }
  &--nesquik {
    @extend .s-project__info--baby;
    background-color: $nesquik-1;
    color: #fff;
    padding-bottom: rem(128);
  }
  &--uncommon {
    @extend .s-project__info--baby;
    background-color: $uncommon-3;
    color: #fff;
    padding-top: 0;
    padding-bottom: rem(147);
  }
  &--gsk {
    @extend .s-project__info--baby;
    background-color: $gsk-1;
    color: #fff;

    padding-bottom: rem(184);

    @media (max-width: $lg) {
      padding-bottom: rem(56);
    }
  }
  &--suzuki {
    @extend .s-project__info--baby;
    background-color: #145169;
    color: #fff;
    padding-bottom: rem(160);

    @media (max-width: $lg) {
      padding-bottom: rem(56);
    }
  }
}
