/**
 * Feature section.
 */
.banner-text {
    /**
	 * Sliding text.
	 */
    .swiper-wrapper {
        transition-timing-function: linear; // Continuous looping linear moving slider.
    }

    // Slide.
    .swiper-slide {
        display: flex;
        align-items: center;
        width: auto;
        &:nth-child(2n + 1) {
            margin-top: 0;
        }
    }

    // Oval.
    .swiper-oval {
        position: relative;
        display: grid;
        place-items: center;
        width: rem(49);
        height: rem(49);
        margin: 0 rem(56);
        border-radius: 50%;
        background-image: linear-gradient(120deg, #ffd6d6 -2%, #e8194b 63%);

        @media (max-width: $md) {
            width: rem(33);
            height: rem(33);
            margin: 0 rem(24);
        }

        &::before {
            content: "";
            position: absolute;
            width: 90%;
            height: 90%;
            border-radius: 50%;
            background-color: $background;
        }
    }

    // Text.
    .swiper-text {
        padding: 2.5px;
        font-family: $font-secondary;
        font-size: rem(100);
        line-height: 1.1;
        color: $background;
        -webkit-text-stroke: 3px transparent;
        background-image: linear-gradient(96deg, #ffd6d6 4%, #e8194b 81%);
        -webkit-background-clip: text;

        @media (max-width: $md) {
            font-size: rem(60);
        }
    }
}
