.video-grid {
  h2 {
	margin-top: rem(160);
	margin-bottom: rem(50);

	@media (max-width: $xl) {
	  margin-top: rem(80);
	  margin-bottom: rem(30);
	}
  }
  &__container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: rem(8);
	@media (max-width: $md) {
	  grid-template-columns: 1fr;
	}
  }
}
