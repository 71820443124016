.s-headingWithBottomImage {
	&__image {
		width: 100%;

		margin-top: rem(43);

		img {
			width: 100%;

			object-fit: cover;
		}
	}

	@media (min-width: $xl) {
		&__text {
			&.l-container {
				max-width: rem(1220);
			}
		}
	}

	.heading {
		margin-bottom: rem(20);

		font-size: rem(120);
		line-height: rem(136);

		@media (max-width: $lg) {
			font-size: rem(80);
			line-height: rem(84);
		}

		@media (max-width: $md) {
			font-size: rem(40);
			line-height: rem(44);

			&.entry {
				transform: none !important;
				white-space: normal;
			}
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		margin-top: rem(27);

		font-family: $font-base;
		font-size: rem(18);
		font-weight: 700;
		line-height: rem(24);
		text-align: center;
		color: #d1d1d6;
	}

	&--events {
		figure {
			text-align: center;

			img {
				width: auto;

				@media (min-width: $lg){
					max-width: 60%;
				}
			}
		}
	}
}
