.blocks-grid {
	display: grid;

	grid-template-columns: 1fr;

	max-width: 100%;

	margin-top: rem(70);

	row-gap: rem(30);


	@media (min-width: $md) {
		grid-template-columns: repeat(2, 1fr);

		width: 94%;

		column-gap: rem(30);
		row-gap: rem(30);
	}
	@media (min-width: $lg) {
		column-gap: rem(83);
		row-gap: rem(86);
	}

	&__element {
		padding: rem(26) rem(15) rem(32) rem(32);

		font-size: rem(18);
		font-weight: 300;
		line-height: 160%;
		color: #FFF;

		border-radius: rem(16);
		background-color: #ED1442;
		.c-btn {
			display: inline-block;

			padding: rem(7) rem(20) rem(8) rem(20);

			margin-top: rem(16);
		}
	}

	&__header-caption {
		font-size: rem(18);
		font-weight: 700;
		color: #FFD60A;
	}
	&__header-title {
		margin: 0 0 rem(10);

		font-size: rem(48);
		font-weight: 900;
		line-height: 1.2;
	}
}
