.images-grid-video {
  div {
	display: flex;
	flex-wrap: wrap;
  }

  img {
	&:nth-of-type(1) {
	  width: 50%;
	  aspect-ratio: 737 / 400;
	}
	&:nth-of-type(2) {
	  width: 50%;
	  aspect-ratio: 736 / 400;
	}
	&:nth-of-type(3) {
	  width: 45.27%;
	  aspect-ratio: 652 / 435;
	}
	&:nth-of-type(4) {
	  width: 54.73%;
	  aspect-ratio: 798 / 435;
	}

	@media (max-width: $sm) {
	  width: 100%!important;
	}
  }
}

.images-gallery-video {
  max-width: rem(1350);
  padding-left: rem(16);
  padding-right: rem(16);
  margin: 0 auto;

  &--wrapper {
	display: flex;
	flex-wrap: nowrap;
	position: relative;
	height: min(745px, calc(600 / 1140 * 100dvw));

	@media (max-width: $md) {
	  display: block;
	  height: auto;
	}
  }

  img {
	height: auto;
	position: absolute;

	&:nth-of-type(1) {
	  width: min(328px, calc(328 / 1440 * 100dvw));
	  aspect-ratio: 328 / 437;
	  z-index: 2;
	}

	&:nth-of-type(2) {
	  width: min(517px, calc(517 / 1440 * 100dvw));
	  aspect-ratio: 517 / 337;
	  z-index: 2;
	  left: min(412px, calc(412 / 1440 * 100dvw));
	  top: min(89px, calc(89 / 1440 * 100dvw));
	}

	&:nth-of-type(3) {
	  width: min(650px, calc(650 / 1440 * 100dvw));
	  aspect-ratio: 650 / 365;
	  z-index: 1;
	  left: min(75px, calc(75 / 1440 *100dvw));
	  top: min(380px, calc(380 / 1440 *100dvw));
	}

	&:nth-of-type(4) {
	  width: min(426px, calc(426 / 1440 * 100dvw));
	  aspect-ratio: 426 / 711;
	  top: 0;
	  right: 0;
	  z-index: 1;
	}

	@media (max-width: $md) {
	  position: initial;
	  width: 100%!important;
	  height: auto;
	  margin-bottom: rem(16);
	}
  }
}
