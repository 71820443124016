.banner-text-2 {
  padding-bottom: rem(116);
  padding-top: rem(110);
  background-color: #ed1442;
  color: #fff;
  font-size: rem(72);
  line-height: 1.2;
  letter-spacing: rem(-0.9);
  font-weight: 900;
  min-height: rem(575);
  @media (max-width: $md) {
    font-size: rem(32);
  }
  @media (max-width: $lg) {
    padding-top: rem(70);
    padding-bottom: rem(80);
  }
  span {
    display: block;
  }
  .typed-cursor {
    display: none;
  }
  &__wrapper {
    width: rem(1029);
    max-width: 100%;
  }
}
