.c-sectionHeading {
    margin-bottom: rem(32);
    h2 {
        font-family: $font-base;
        font-size: rem(18);
        font-weight: 300;
        @media(max-width: $lg) {
            font-size: rem(16);
        }
    }
    &--ai {
        margin-bottom: rem(40);
    }
}