.paid-media-grid {
  margin-bottom: 11.625rem;

  @media (max-width: $lg) {
	margin-bottom: 7rem;
  }

  &__cols {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
  }

  img {
	width: 100%;
	height: auto;
  }

  &__col {
	display: flex;
	flex-direction: column;
	gap: rem(40);

	&-1 {
	  width: 13.1%;
	  @media (max-width: $md) {
		order: 1;
		width: 26%;
	  }
	}
	&-2 {
	  margin-top: rem(68);
	  width: 40.37%;
	  @media (max-width: $md) {
		order: 3;
		width: 62%;

	  }
	}
	&-3 {
	  margin-top: rem(45);
	  width: 22.65%;

	  @media (max-width: $md) {
		order: 2;
		width: 70%;
	  }

	  img:last-of-type {
		padding-left: rem(24);
		padding-right: rem(60);
	  }
	}
	&-4 {
	  width: 16.64%;

	  @media (max-width: $md) {
		margin-top: rem(20);
		width: 34%;
		order: 4;
	  }
	}
  }

}
