.st-underline {
    position: relative;
    &::before {
        content: '';
        width: 100%;
        position: absolute;
        height: 2px;
        background-color: #fff;
        bottom: rem(-1);
        left: 0;
    }
}