.vr-specialism {
	margin-top: rem(78);
	&__heading {
		margin-bottom: rem(14);
	}
	&__text {
		margin-bottom: rem(65);

		font-weight: 200;
	}
	&__footer {
		margin-top: rem(80);

		font-family: $font-secondary;
		font-size: rem(72);
		line-height: rem(82);
		text-align: center;

		@media (max-width: $lg) {
			margin-top: rem(60);

			font-size: rem(45);
		}

		@media (max-width: $md) {
			font-size: rem(34);
			line-height: 1.6;
		}
		strong {
			color: $color-5;
		}
	}
}
