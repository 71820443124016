.s-contact-buttons {
	position: relative;
	background-color: $background;
	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		column-gap: rem(30);
		row-gap: rem(16);
		@media (max-width: $lg) {
			column-gap: rem(16);
			row-gap: rem(16);
		}
	}
	.c-btn {
		&--contact {
			font-size: rem(24);
			padding: rem(10) rem(20);
			border-radius: rem(9999999);
			display: flex;
			align-items: center;
			justify-content: center;
			.arrow {
				margin-top: rem(8);
			}
			.phone ,
			.whatsapp {
				transition: color $transition-speed, background $transition-speed, width $transition-speed;
				margin-left: rem(16);
				display: inline-block;
				@media (max-width: $lg) {
					scale: 0.8;
					margin-left: rem(10);
				}
			}
			.phone {
				width: rem(26);
				height: rem(24);
				background-color: #fff;
				-webkit-mask-image: url("../img/phone.svg");
				mask-image: url("../img/phone.svg");
			}
			.whatsapp {
				width: rem(38);
				height: rem(38);
				background-color: #32D94F;
				-webkit-mask-image: url("../img/whatsapp.svg");
				mask-image: url("../img/whatsapp.svg");
				margin-top: rem(-8);
				margin-bottom: rem(-8);

			}
			&:hover {
				.phone {
					background-color: $color-1;
				}
			}
			@media (max-width: $lg) {
				padding: rem(8) rem(16);
				font-size: rem(18);
			}
		}
		&--whatsapp {
			border-color: #32D94F;
			padding-right: rem(12);
			@media (max-width: $lg) {
				padding-right: rem(6);
			}
		}
	}
}
