/**
 * Site base styles.
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}
section {
  margin-top: -1px;
}
html,
body,
h1,
h2,
h3,
p,
a,
img,
header,
footer,
nav,
ol,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

body {
  font-size: rem(18);
  font-weight: 300;
}

html {
  font-family: $font-base;
  line-height: 1.6;
  color: $text-color-base;
  background-color: $background;
  scroll-behavior: smooth;
  @include scrollbar($color-3, $color-1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  line-height: 1.1;
  font-weight: 700;
}

h1 {
  font-size: rem(80);
}

h2 {
  font-size: rem(60);
}

h3 {
  font-size: rem(45);
}

h4 {
  font-size: rem(34);
}

h5 {
  font-size: rem(24);
  font-family: $font-base;
}

h6 {
  font-size: rem(18);
  font-family: $font-base;
}

a {
  color: inherit;
  text-decoration: none;
  &:not([class]):hover {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

input,
button {
  all: unset;
}

input,
textarea {
  cursor: text;
  &::placeholder {
    color: $text-color-base;
    transition: color $transition-speed;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

input[type="submit"],
button {
  cursor: pointer;
}

.overflow {
  overflow: hidden;
}

textarea {
  font-family: $font-base;
}

main {
  overflow: hidden;
}

// Cookies

// #onetrust-consent-sdk,
// #onetrust-banner-sdk {
//   opacity: 0;
//   visibility: hidden;
//   pointer-events: none;
// }

// #onetrust-consent-sdk.visible,
// #onetrust-banner-sdk.visible {
//   opacity: 1;
//   visibility: visible;
//   pointer-events: all;
// }

.heading-hidden {
  @media (max-width: $lg) {
    display: none;
  }
}
