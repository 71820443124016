.c-four-mobiles {
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;

  .gsk__mobiles--img & {
    padding: 0 30px;
    @media (max-width: $lg) {
      padding: 0 16px;
    }
    @media (max-width: $sm) {
      padding: 0;
    }
  }

  @media (max-width: $lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    justify-items: center;
    margin-bottom: rem(0);
  }
  &__img {
    &:nth-child(even) {
      margin-top: rem(80);
      margin-inline: rem(30);
      @media (max-width: $lg) {
        margin-inline: 0;
        margin-top: 0%;
      }
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
    @media (max-width: $lg) {
      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;
      }
      &:nth-child(2) {
        grid-area: 2 / 2 / 4 / 3;
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 5 / 2;
      }
      &:nth-child(4) {
        grid-area: 4 / 2 / 6 / 3;
      }
    }
    &__parts {
      &__after {
        margin-top: rem(16);
      }
    }
    &--even {
      background-color: red;
      margin-top: -100px;
    }
  }
}
