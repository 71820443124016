.grid-1 {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 25%;
    max-width: 236px;
    transform: scale(1);
    &:nth-child(1) {
      margin-top: 35%;
    }
    @media (max-width: $md) {
      width: 25%;
    }
  }
}

.grid-2 {
  display: flex;
  flex-direction: column;
  &-up {
    display: flex;

    &-el {
      &:nth-child(2) {
        margin-top: rem(50);
      }
    }
  }
  &-down {
    margin-top: rem(-75);

    width: 63%;
    display: flex;
    justify-content: flex-end;
  }
}

.grid-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  row-gap: 20px;
  &--up {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    column-gap: 20px;

    img {
      width: 100%;
      flex-shrink: 1;
      &:nth-child(2) {
        align-self: center;
      }
    }
  }
  &--down {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    img {
      width: 100%;
      max-width: 469px;
    }
    .grid-3-el {
      &:nth-child(2) {
        margin-top: rem(-40);
        @media (max-width: $xl) {
          margin-top: 0;
        }
      }
    }
  }
}

.grid-4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 32px;

  &--up {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }
  &-el-posa {
    position: absolute;
    &-1 {
      left: -3%;
      top: 50%;
      z-index: -1;
      @media (max-width: $xl) {
        top: -20%;
        left: 15%;
        width: 40%;
        z-index: 2;
      }
    }
    &-2 {
      right: -3%;
      top: -10%;
      @media (max-width: $xl) {
        top: -30%;
        left: 60%;
        width: 30%;
      }
    }
  }
  &--down {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: rem(32);
    &--el {
      &:nth-child(1) {
        margin-top: rem(50);
      }
    }
  }
}

.grid-5 {
  display: flex;
  column-gap: 2%;
  padding-inline: rem(50);
  position: relative;
  @media (max-width: $md) {
    max-width: 575px;
    margin-inline: auto;
    padding-inline: rem(0);
  }
  &-el {
    &-2 {
      margin-top: rem(-50);
      @media (max-width: $md) {
        position: absolute;
        width: 23%;
        top: 0;
        left: 20%;
      }
    }
    &-3 {
      margin-top: rem(50);
      @media (max-width: $md) {
        position: absolute;
        width: 23%;
        top: -133px;
        left: 52%;
      }
    }
  }
}

.grid-6 {
  width: 100%;
  background-color: red;
  padding-inline: rem(53);
  position: relative;
  &-el {
    position: absolute;
    &-4 {
      left: 42%;
      top: -20%;
    }
  }
}

.grid-7 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: rem(20);
  &-up {
    display: flex;
  }
  &-down {
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-top: rem(-150);
    @media (max-width: $lg) {
      margin-top: 0;
    }
    &-1 {
      margin-top: rem(100);
    }
    &-3 {
      margin-top: rem(-100);
    }
  }
}

.grid-8 {
  display: flex;
  column-gap: 9%;
  width: 100%;
  max-width: 1292px;
  margin-inline: auto;
  position: relative;
  &__2 {
    column-gap: 24px;
    .grid-8__mobiles {
      bottom: 10%;
    }
  }
  &__right {
    &__2 {
      margin-top: rem(40);
    }
    &__last {
      width: 60%;
    }
  }
  &__left {
    position: relative;
    @media (max-width: $lg) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__first {
    @media (max-width: $lg) {
      opacity: 0;
    }
  }
  &__mobiles {
    position: absolute;
    bottom: -20%;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: $lg) {
      top: -20%;
      left: 15%;

      margin-inline: auto;
    }
    @media (max-width: $md) {
      left: 50px;

      width: 80%;
    }

    img {
      max-width: 224px;
      @media (max-width: $md) {
        width: 50%;
      }
      &:first-child {
        margin-top: 35px;
      }
    }
  }
}
.grid-9 {
  width: 100%;
  margin-top: rem(160);
  margin-bottom: rem(200);
  @media (max-width: $lg) {
    margin-top: rem(80);
    margin-bottom: rem(100);
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 32px;
    margin-bottom: 32px;
    &--2 {
      margin-top: 32px;
    }
  }
  &__left {
    display: flex;
    width: 100%;
    gap: 32px;
    margin-bottom: 32px;
    &--1 {
      margin-top: -32px;

      @media (max-width: $lg) {
        margin-top: 0;
      }
    }
  }

  @media (max-width: $lg) {
    padding: 0 rem(16);

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-bottom: rem(32);

      &:last-child {
        margin-bottom: 0;
      }

      & > div {
        width: 85%;
        margin: 0;
        margin-bottom: rem(32);

        &:nth-child(2) {
          margin-left: auto;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        border-radius: rem(10);
      }
    }
  }
}

.grid-10 {
  width: 100%;
  aspect-ratio: 1.5/1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(220);
  margin-bottom: rem(320);
  @media (max-width: $lg) {
    margin-bottom: rem(160);
  }
  &--1 {
    position: absolute;
    top: 23%;
    left: 15%;
    width: 66%;
  }
  &--2 {
    position: absolute;
    top: 39%;
    left: 3%;
    width: 20%;
  }
  &--3 {
    position: absolute;
    top: -3%;
    left: 22%;
    width: 20%;
  }
  &--4 {
    position: absolute;
    top: 49%;
    left: 42%;
    width: 20%;
  }
  &--5 {
    position: absolute;
    top: 33%;
    left: 75%;
    width: 20%;
  }
}
